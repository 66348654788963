// import axios from "";
import axios from "../../config/axiosAuth";
import {
  Dialog,
  DialogContent,
  DialogTrigger,
} from "../../components/ui/dialog";
import { baseUrl } from "../../config/baseUrl";
import { useEffect, useState } from "react";

export function DescriptionModal({
 data,
  trigger,
}) {
  const [open, setOpen] = useState(false);
 
  useEffect(() => {
    
  }, [open]);
  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild >
      <div className="flex items-center justify-center text-center cursor-pointer">{trigger}</div> 
      </DialogTrigger>
      <DialogContent style={{minWidth:'500px',display:'flex'}} className='!max-w-fit'>

        <>
          <div>
            <div
              style={{
                padding: "20px",
                textAlign: "center",
                width:'500px'
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  borderBottom: "2px solid gray",
                  marginBottom: "10px",
                  alignItems: "center",
                }}
              ></div>
              <div className="!max-h-[400px] max-w-[450px]  h-max-content max-w-[500px]" style={{whiteSpace: 'pre-line',
    width:' 450px',
    /* text-overflow: ellipsis; */
    overflow: 'hidden',
    wordWrap: 'break-word'}}>
               {data}
              </div>
            </div>
          </div>
        </>
      </DialogContent>
    </Dialog>
  );
}
