import { clsx } from "clsx";
import { twMerge } from "tailwind-merge";
import { getCurrentLocation } from "./transformData";
import { baseUrlUser } from "../user/config/baseUrl";
import axios from '../user/config/axiosAuth'
export function cn(...inputs) {
  return twMerge(clsx(inputs));
}
export const handleKeyDown = (event) => {
  if (event.key === ' ') {
    event.preventDefault();
  }
};
export function formatDateTime(isoDateString) {
  const date = new Date(isoDateString);
  const options = {
    year: "numeric",
    month: "short",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
    hour12: false,
  };
  return date.toLocaleDateString("en-US", options);
}
export const logoutFunc = async () => {
  const { latitude, longitude } = await getCurrentLocation();
console.log('loggingout',latitude,longitude)
  try {
    const response = await axios.post(baseUrlUser + "/employeeLogOut", {
      lat: latitude,
      long: longitude,
    });

    localStorage.clear();
    window.location.href = "/";
  } catch (err) {
    throw new Error(err);
  }
  // })
  // .catch((err) => {
  //   console.log(err);
  // });
};
export function formatProgress(progress) {
  // Convert to number if it's not already
  const numProgress = Number(progress);
  
  // Check if it's a valid number
  if (!isNaN(numProgress)) {
    return numProgress.toFixed(1);
  } else {
    return "Invalid progress value";
  }
}