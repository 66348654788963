import axios from "./../config/axiosAuth";
const { baseUrlUser, baseUrlCommon } = require("../config/baseUrl");

const reportServices = {
  getAllReportUser: async () => {
    const res = await axios.get(`${baseUrlUser}/allReports`);
    return res.data.result;
  },
  getAllTeamReportUser:async () => {
    const res = await axios.get(`${baseUrlUser}/teamReports`);
    return res.data.result;
  },
  getAccessReportsById:async()=>{

  },
  editTeamReportUser: async (data) => {
    console.log("Form data: service", data);
    const res = await axios.put(`${baseUrlUser}/teamReports/`,{id:data._id, report:data?.report});
    return res.data;
  },
  addTeamReport:async (data) => {
    const res = await axios.post(`${baseUrlUser}/addTeamReport`, data);
    return res.data;
  },
  addReportUser: async (data) => {
    const res = await axios.post(`${baseUrlUser}/addReport`, data);
    return res.data;
  },
  editAccessReportUser: async (data) => {
    const res = await axios.put(`${baseUrlUser}/report`,data);
    return res.data;
  },

  editReportUser: async (id, data) => {
    const res = await axios.put(`${baseUrlUser}/editReport/${id}`, data);
    return res.data;
  },

  submitCommentUser: async (data) => {
    const res = await axios.put(`${baseUrlUser}/addComment/`, data);
    return res.data;
  },

  submitCommentCommon: async (data) => {
    const res = await axios.put(`${baseUrlCommon}/addComment/`, data);
    return res.data;
  },

  editCommentCommon: async (data) => {
    const res = await axios.put(`${baseUrlCommon}/editComment/`, data);
    return res.data;
  },

  deleteCommentCommon: async (data) => {
    const res = await axios.post(`${baseUrlCommon}/deleteComment/`, data);
    return res.data;
  },

  getUserAccessReport: async () => {
    const res = await axios.get(`${baseUrlUser}/accessReport`);
    return res.data.result;
  },
  getAccessReportsUser:async ()=>{
    const res = await axios.get(`${baseUrlUser}/accessed-user`);
    return res.data.result;
  }
};

export default reportServices;
