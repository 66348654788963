import React, { useEffect, useState } from "react";
import { DataTable } from "../../components/table/Data-Table";
import { baseUrl } from "../../config/baseUrl";
import axios from "../../config/axiosAuth";
import { hexToRGBA } from "../../../utils/lib";
import moment from "moment";
import useProjectHook from "../../hooks/useProjectHook";
import { useNavigate } from "react-router-dom";
import { projectServices } from "../../service/projectService";
import { useQuery } from "react-query";

const GoTOProjectDetails = ({ id }) => {
  const navigate = useNavigate();
  const fetchOneProject = async () => {
    const data = await projectServices.getOneProject(id);
    return data;
  };
  const { data: project } = useQuery(["oneProject", id], fetchOneProject);
  const gotoProjectHistory = () => {
    navigate("/projectHistory", { state: { project } });
  };
  return <div onClick={gotoProjectHistory}>View</div>;
};
const EmployeeColumn = [
  {
    header: "S.No",
    cell: ({ row }) => <span>{row.index + 1}</span>,
  },
  {
    header: "Project Name",
    cell: ({ row }) => row?.original?.projectName,
  },
  {
    header: "Project StartDate",
    cell: ({ row }) =>
      moment(row?.original?.projectStartDate).format("DD-MM-YYY"),
  },
  {
    header: "Project EndDate",
    cell: ({ row }) =>
      moment(row?.original?.projectEndDate).format("DD-MM-YYY"),
  },
  {
    header: "Project Status",

    cell: ({ row }) => (
      <div
        className="w-max rounded-xl h-[40px] text-center p-[10px] m-auto flex items-center justify-center "
        style={{ backgroundColor: hexToRGBA('#008000', 0.3) }}
      >
        <p className="text-[14px]">{row?.original?.status}</p>
      </div>
    ),
  },
  {
    header: "View History",
    cell: ({ row }) => <GoTOProjectDetails id={row?.original?._id} />,
  },
];
const CompletedProjects = () => {
  const { totalCompletedProjects, completedData } = useProjectHook();

  return (
    <DataTable
      columns={EmployeeColumn}
      data={completedData?.project}
      defaultTheme={true}
    />
  );
};

export default CompletedProjects;
