import React, { useMemo } from 'react'
import { DataTable } from '../../components/table/Data-Table'
import useEmployeeHook from '../../hooks/useEmployeeHook'
import { Trash } from 'lucide-react'
import CommonAlert from '../../components/ui/CommonAlert'
const DeletedUsers = () => {
    const {deletedUsers}=useEmployeeHook()
    const columns = useMemo(
        () => [
          {
            header: "S.No",
            cell: ({ row }) => {
              return <span>{row.index + 1}</span>;
            },
          },
    
          {
            cell: ({ row }) => {
              return <span>{row.original?.name}</span>;
            },
            header: "Name",
          },
          {
            cell: ({ row }) => {
              return <span>{row.original?.email}</span>;
            },
            header: "Email",
          },
    
          {
            cell: ({ row }) => {
              return <span>{row.original?.employeeId}</span>;
            },
            header: "Employee Id",
          },
          {
            accessorKey: "designation",
            cell: ({ row }) => row.original?.designation?.name,
          },
          {
            cell: ({ row }) => row.original?.role,
            header: "Role",
          },
          {
            header: "Delete Permenantly",
            cell: ({ row }) => <DeletePermenantly row={row} />,
          },
    
        //   {
        //     // accessorKey: "password",
        //     cell: ({ row }) =>
        //       moment(row?.original?.createdAt).format("DD-MM-yyyy"),
        //     header: "Request sent on",
        //   },
    
          // {
          //   // accessorKey: "password",
          //   cell: ({ row }) => <Password password={row?.original?.password} />,
          //   header: "Password",
          // },
        //   {
        //     // accessorKey: "password",
        //     cell: ({ row }) => <ResendCredentials employeeId={row.original?.employeeId?._id} />,
        //     header: "Send Credentials",
        //   },
        ],
        [deletedUsers]
      );
  return (
  <DataTable data={deletedUsers||[]} heading='Deletd Users' columns={columns} defaultTheme={true}/>
  )
}

export default DeletedUsers
const DeletePermenantly = ({ row }) => {
  const { DeletePermenantlyMutation } = useEmployeeHook();
  const deleting = (id) => {
    DeletePermenantlyMutation.mutate(id);
  };
  return (
    <CommonAlert
      trigger={<Trash style={{ color: "blue", margin: "auto" }} />}
      onClick={() => deleting(row.original?._id)}
      heading={"Delete"}
      text={"Are you sure you want to delete ?"}
    />
  );
};