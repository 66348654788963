import React, { useMemo } from 'react'
import { DataTable } from '../../components/table/Data-Table'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { formatDateTime } from '../../../utils/lib'
import useReportHook from '../../hooks/ReportHook'

const LeaderTeam = () => {
    const location=useLocation()
    const navigate=useNavigate()
    const params=useParams()
    const{leaderById}=useReportHook(params.id)
    const leaderColumns = useMemo(()=>[
        {
          header: "S.No.",
          cell: ({ row }) => row.index + 1,
        },
    
        {
          header: "Name",
          cell: ({ row }) => {
            return row.original?.name;
          },
        },
        {
            header: "Employee ID",
            cell: ({ row }) => {
              return row.original?.employeeId;
            },
          },
        {
          header: "Designation",
          cell: ({ row }) => {
            return row.original?.designation?.name;
          },
        },
        {
            header: "Role",
            cell: ({ row }) => {
              return row.original?.role;
            },
          },
        {
            header: "Email ID",
            cell: ({ row }) => {
              return row.original?.email;
            },
          },
       
        // {
        //   accessorKey: "taskAssigned",
        //   header: "Task Assigned",
        // },
       
        {
          header: "View Reports ",
          cell: ({ row }) => <button className="button-17 !rounded-[10px]" onClick={()=>navigate(`/leaderTeamReport/${row.original?._id}`)}>View</button>
    
          
        },
      
       
       
        // {
        //   header: "Add Comment",
        //   cell: ({ row }) => <AddCommentModal id={row.original._id} />,
        // },
      ],[]);
  return (
    <DataTable columns={leaderColumns} data={leaderById||[]} defaultTheme={true}  heading={`Team under ${location?.state?.name}`} TableHeight={'350px'}/>

  )
}

export default LeaderTeam
