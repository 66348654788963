import reportServices from "../service/reportServices";

const { useQuery, useQueryClient, useMutation } = require("react-query");

const useReportHook = (id) => {
  const queryClient = useQueryClient();

  const fetchAllReportsAdmin = async () => {
    const res = await reportServices.getAllReportAdmin();
    return res;
  };
  const fetchAllLeaders = async () => {
    const res = await reportServices.getAllLeaders();
    return res;
  };

const fetchLeaderById=async()=>{
  const res = await reportServices.getLeaderById(id);
  return res;
};
  const { data, isError, isLoading } = useQuery(
    "allReports",
    fetchAllReportsAdmin
  );
  const { data:leaders, } = useQuery(
   "allLeaders",
   fetchAllLeaders
  );
  const { data:leaderById, } = useQuery(
    ["leaderById",id],
    fetchLeaderById,{
      enabled:!!id
    }
  );

  const giveAccessToReportMutation = useMutation(
    reportServices.giveAccessToReport,
    {
      onSuccess: (data) => {
        queryClient.invalidateQueries("allReports");
        queryClient.invalidateQueries(["accessReports"]);
      },
    }
  );

  return {
    data,
    isError,
    isLoading,
    giveAccessToReportMutation,
    leaders,
    leaderById
  };
};

export default useReportHook;
