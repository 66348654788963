// import axios from "";
import { DescriptionModal } from '../../../PageResponsibility/src/admin/components/modals/DiscriptionModal';
import axios from '../../../PageResponsibility/src/admin/config/axiosAuth'
import { baseUrl, baseUrlUser } from '../../config/baseUrl';
import {
  Dialog,
  DialogContent,
  DialogTrigger,
} from "../ui/dialog";
import { useState } from "react";

export function CommentDialog({ id,commonId,model,}) {
  const [data, setData] = useState([]);
  const handleComment = () => {
    axios
      .post(baseUrlUser + '/get-comments', {
        id: id,
        commonId:commonId,
        model:model
      })

      .then((res) => {
        // console.log(res.data);
        setData([...res.data.adminComments, ...res.data.employeeComments]);
      })

      .catch((err) => {
        // console.log(err);
        throw new Error(errr)
      });
  };
  // console.log(data);

  return (
    <Dialog>
      <DialogTrigger asChild>
        <button
          onClick={handleComment}
          className="button-17 text-xs w-28 !rounded-[10px]"
          variant="outline"
        >
          Comments
        </button>
      </DialogTrigger>
      <DialogContent className='!max-w-fit'>
        <div>
          <div
            style={{
              padding: "20px",
              textAlign: "center",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                borderBottom: "2px solid gray",
                marginBottom: "10px",
                alignItems: "center",
              }}
            ></div>
            <table>
              <thead>
                <tr
                  style={{
                    backgroundColor: "#f1f4f7",
                    color: "gray",
                  }}
                >
                  <th>Name</th>
                  <th>Comment</th>
                  <th>Comment By</th>
                  <th>Designation</th>
                </tr>
              </thead>
              <tbody className="tableBody">
                {data?.length > 0 ? (
                  data?.map((receiver, index) => (
                    <tr key={index}>
                      <td>{receiver?.commentBy || "-"}</td>
                      <td >{receiver?.comment?receiver?.comment?.length>14? <DescriptionModal trigger={<span className="flex w-[100px] overflow-hidden truncate">{`${receiver?.comment.substring(0,14)}....`}</span>} data={receiver?.comment}/>:receiver?.comment:'-'}</td>

                      <td>
                        {receiver?.commentBy === "Admin" ? "Admin" : "Employee"}
                      </td>
                      <td>{receiver?.designation?receiver?.designation?.name :receiver?.commentBy === "Admin" && "Admin"}</td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="4">No Comments</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
}
