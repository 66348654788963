import React, { useEffect, useMemo, useState } from "react";
import { DataTable } from "../../../PageResponsibility/src/admin/components/table/Data-Table";
import { useLocation } from "react-router-dom";
import { columns } from "../../../PageResponsibility/src/admin/pages/projects/columns";
import { baseUrlUser } from "../../config/baseUrl";
import axios from "axios";
import moment from "moment";
import { DescriptionModal } from "../../../PageResponsibility/src/admin/components/modals/DiscriptionModal";

const ViewTeamReport = () => {
  const location = useLocation();
  const [data, setData] = useState([]);
  console.log(location,'locationlocation')
  const GetTeamReportsByDate = async() => {
    const response = await axios.get(
      baseUrlUser + `/teamReportsByDate?date=${location?.state?.data}`
    );
    setData(response?.data?.result);
    console.log(response)
  };
  useEffect(() => {
    GetTeamReportsByDate();
  }, []);

  const userTeamcolumns = useMemo(
    () => [
      { header: "S.No", cell: ({ row }) => row.index + 1 },
      {
        header: "Date",
        cell: ({ row }) => (
          <div className="!w-[150px] m-auto">
            {row?.original?.date?moment.utc(row?.original?.date).format('DD-MM-YYYY'):'-'}
          </div>
        ),
      },
      {
        header: "Report created For",
        cell: ({ row }) =>
          row.original?.createdFor?.name
           
      },
      {
        header: "EmployeeId",
        cell: ({ row }) =>
          row.original?.createdFor?.employeeId
           
      },
      {
        header: "Report",
        cell:({row})=>row?.original?.report?row?.original?.report.length>14? <DescriptionModal trigger={<span className="flex w-[100px] overflow-hidden truncate">{`${row?.original?.report.substring(0,14)}....`}</span>} data={row?.original?.report}/>:row?.original?.report:'-'

           
      },
    //   {
    //     header: "View Report",
    //     // cell:({row})=>row?.original?.report?.length>14? <DescriptionModal trigger={<span className="flex w-[100px] overflow-hidden truncate">{`${row?.original?.report?.substring(0,14)}....`}</span>} data={row?.original?.report}/>:row?.original?.report
    //     cell:({row})=>  <button
    //     onClick={() =>
    //       navigate("/viewTeamReport", {
    //         state: {
    //           data: row?.original,
    //         },
    //       })
    //     }
    //     style={{
    //       width: "100px",
    //       padding: "0",
    //       fontSize: "11px",
    //     }}
    //     className="button-17"
    //   >
    //     View Report
    //   </button>

    //   },
      // {
      //   header: "Edit",
      //   cell: ({ row }) =><EditTeamReportModal id={row?.original?._id} report={row?.original?.report}/>},
          
      // {
      //   header: "Add Comment",
      //   cell: ({ row }) => (
      //     <AddCommentModal id={row.original._id} model={"report"} />
      //   ),
      // },
      // {
      //   header: "Comment",

      //   cell: ({ row }) => {
      //     return <CommentDialog id={row.original._id} model="report" />;
      //   },
      // },
    ],
    [data]
  );
  return (
    <DataTable
      data={data||[]}
      columns={userTeamcolumns}
      heading="Team Reports"
      defaultTheme={true}
    ></DataTable>
  );
};


export default ViewTeamReport;
