import { RiEditBoxFill } from "react-icons/ri";
import { Link } from "react-router-dom";
import { AddCommentModal } from "../reports/AddCommentModal";
import moment from "moment";

import { CommentDialog } from "../../components/modals/CommentDialog";
import { blue } from "@mui/material/colors";
import { EditStatusModal } from "../../components/modals/EditStatus";
import { Edit } from "lucide-react";
import { DescriptionModal } from "../../components/modals/DiscriptionModal";
import { AlertDialog, AlertDialogAction, AlertDialogCancel, AlertDialogContent, AlertDialogDescription, AlertDialogFooter, AlertDialogHeader, AlertDialogTitle } from "../../components/ui/alert-dialog";
import { useState } from "react";
import { getStatusStyle } from "../../../utils/styles";

const styles = {
  Active: {
    color: "green",
  },
  Inactive: {
    color: "orange",
  },
  Progress: {
    color: "red",
  },
};
export const TesterColumns = [
  {
    header: "S.No",
    cell: ({ row }) => {
      return <span>{1}</span>;
    },
  },
  {
    header: "S.No",
    cell: ({ row }) => {
      return;
    },
  },
  {
    header: "Project Name",
    cell: ({ row }) => {
      return;
    },
  },
  {
    header: "Category",
    cell: ({ row }) => {
      return;
    },
  },
  {
    header: "Assign To",
    cell: ({ row }) => {
      return;
    },
  },
  {
    header: "Description",
    cell: ({ row }) => {
      return;
    },
  },
  {
    header: "Developer status",
    cell: ({ row }) => {
      return;
    },
  },
  {
    header: "Bug Status By Tester",
    cell: ({ row }) => {
      return;
    },
  },
  {
    header: "Add",
    cell: ({ row }) => {
      return;
    },
  },
  {
    header: "Comment",
    cell: ({ row }) => {
      return;
    },
  },
  {
    header: "Edit",
    cell: ({ row }) => {
      return;
    },
  },

  {
    /* <tbody>

      <td>{rowdata?.tak}</td>
      <td>{rowdata.empleId?._id}</td>
      <td>{rowdata.proecId?._id}</td>
      <td>{rowdata.sttus}</td>

      <td>{rowdata.decion}</td>
      <td>{rowdata.decion}</td>
      <td>{rowdata.decion}</td>
      <td>{rowdata.decion}</td>
      <td>{rowdata.decion}</td>
    </tr> */
  },
];
export const recentTasksColumns = [
  {
    header: "S.No",
    cell: ({ row }) => {
      return <span>{row.index + 1}</span>;
    },
  },
  {
    header: "Project Name",
    cell: ({ row }) => {
      return <span>{row?.original.projectId?.projectName}</span>;
    },
  },
  {
    header: "Departemnt",
    cell: ({ row }) => {
      return <span>{row.original.department?.teamName?row.original.department?.teamName:'-'}</span>;
    },
  },
  {
    header: "Employee Name",
    cell: ({ row }) => {
      return row.original.employeeId?.name;
    },
  },
  {
    header: "Start Date",
    cell: ({ row }) => {
      return moment(row.original.projectId?.projectStartDate).format(
        "DD-MM-YYYY"
      );
    },
  },
  {
    header: "End Date",
    cell: ({ row }) => {
      return moment(row.original.projectId?.projectEndDate).format(
        "DD-MM-YYYY"
      );
    },
  },

  {
    header: "Status Of Task",
    cell: ({ row }) => {
      return row.original.status;
    },
  },
  {
    header: "Last Updated At",
    cell: ({ row }) => {
      return row.original.lastStatusUpdated?moment(row.original.lastStatusUpdated).format(
        "DD-MM-YYYY"
      ):'-';
    },
  },
  {
    header: "Add Comment",
    cell: ({ row }) => {
      return (
        <AddCommentModal
          id={row?.original._id}
          url={"/addTaskCommentByAdmin"}
        />
      );
    },
  },
  {
    header: " Comment",
    cell: ({ row }) => {
      return <CommentDialog id={row?.original._id} url={"/getTaskComments"} />;
    },
  },

  {
    header: "Edit",
    cell: ({ row }) => {
      return (
        <span className="flex justify-center">
          {/* <Link to={`/editProject/${row.original._id}`}> */}
          {/* <RiEditBoxFill className="text-lg" /> */}
          <EditStatusModal url={"/taskManagement"} id={row?.original._id} trigger={<RiEditBoxFill className="text-lg" />} options={options}/>
          {/* </Link> */}
        </span>
      );
    },
  },
  {
    header: "Description",
    cell: ({ row }) => {
      return row.original.description;
    },
  },
];
const EditCompletedStatus = ({ row }) => {
  const [showAlert, setShowAlert] = useState(false);
  const [triggerStatusModal, setTriggerStatusModal] = useState(false);

  const handleContinue = () => {
    setShowAlert(false);
    // Instead of directly controlling the modal, we'll trigger the Dialog's trigger
    setTriggerStatusModal(true);
  };

  return (
    <>
      {/* Trigger Button */}
      <div 
        className="flex items-center justify-center gap-2 cursor-pointer" 
        onClick={() => setShowAlert(true)}
      >
        <span style={getStatusStyle(row.original?.status)}>
          {row.original?.status}
        </span>
        <span>
          <Edit size={"12px"} />
        </span>
      </div>

      {/* Alert Dialog */}
      <AlertDialog open={showAlert} onOpenChange={setShowAlert}>
        <AlertDialogContent>
          <AlertDialogHeader>
            <AlertDialogTitle>Change Completed Status</AlertDialogTitle>
            <AlertDialogDescription>
              This project is marked as completed. Are you sure you want to change its status?
            </AlertDialogDescription>
          </AlertDialogHeader>
          <AlertDialogFooter>
            <AlertDialogCancel>Cancel</AlertDialogCancel>
            <AlertDialogAction onClick={handleContinue}>
              Continue
            </AlertDialogAction>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>

      {/* Hidden trigger for EditStatusModal */}
      <div className="hidden">
        <EditStatusModal
          trigger={
            <button ref={triggerRef => {
              if (triggerRef && triggerStatusModal) {
                triggerRef.click();
                setTriggerStatusModal(false);
              }
            }}>
              Hidden Trigger
            </button>
          }
          options={options}
          url={`/updateProjectStatus/${row?.original?._id}`}
          id={row?.original?._id}
          invalidateQuery={["projects", "projectSummary"]}
        />
      </div>
    </>
  );
};

// const EditCompletedStatus = ({ row }) => {
//   const [showAlert, setShowAlert] = useState(false);
//   const [showStatusModal, setShowStatusModal] = useState(false);

//   const handleAlertConfirm = () => {
//     // setShowAlert(false);
//     // setShowStatusModal(true);
//   };

//   return (
//     <>
//       {/* Trigger Button */}
//       <div 
//         className="flex items-center justify-center gap-2 cursor-pointer" 
//         onClick={() => setShowAlert(true)}
//       >
//         <span style={getStatusStyle(row.original?.status)}>
//           {row.original?.status}
//         </span>
//         <span>
//           <Edit size={"12px"} />
//         </span>
//       </div>

//       <AlertDialog open={showAlert} onOpenChange={setShowAlert}>
//         <AlertDialogContent>
//           <AlertDialogHeader>
//             <AlertDialogTitle>Change Completed Status</AlertDialogTitle>
//             <AlertDialogDescription>
//               This project is marked as completed. Are you sure you want to change its status?
//             </AlertDialogDescription>
//           </AlertDialogHeader>
//           <AlertDialogFooter>
//             <AlertDialogCancel onClick={() => setShowAlert(false)}>
//               Cancel
//             </AlertDialogCancel>
//             <AlertDialogAction >
//             <EditStatusModal
//           // isOpen={showStatusModal}
//           trigger={
//            'Continue'
//           }
//           // onClose={() => setShowStatusModal(false)}
//           options={options}
//           url={`/updateProjectStatus/${row?.original?._id}`}
//           id={row?.original?._id}
//           invalidateQuery={["projects", "projectSummary"]}
//           currentStatus={row.original?.status}
//         />
//             </AlertDialogAction>
//           </AlertDialogFooter>
//         </AlertDialogContent>
//       </AlertDialog>

//       {/* Status Edit Modal */}
//       {showStatusModal && (
//         <EditStatusModal
//           isOpen={showStatusModal}
//           onClose={() => setShowStatusModal(false)}
//           options={options}
//           url={`/updateProjectStatus/${row?.original?._id}`}
//           id={row?.original?._id}
//           invalidateQuery={["projects", "projectSummary"]}
//           currentStatus={row.original?.status}
//         />
//       )}
//     </>
//   );
// };

// Component for non-completed status that shows modal directly
const EditNormalStatus = ({ row }) => (
  <EditStatusModal
    trigger={
      <div className="flex items-center justify-center gap-2 cursor-pointer">
        <span style={getStatusStyle(row.original?.status)}>
          {row.original?.status}
        </span>
        <span>
          <Edit size={"12px"} />
        </span>
      </div>
    }
    options={options}
    url={`/updateProjectStatus/${row?.original?._id}`}
    id={row?.original?._id}
    invalidateQuery={["projects", "projectSummary"]}
  />
);

// Status column definition in your columns array

  
const options = [
  { label: "Pending", value: "Pending" },
  { label: "Completed", value: "Completed" },
  { label: "Draft", value: "Draft" },
  { label: "OnGoing", value: "OnGoing" },
  { label: "InProgress", value: "InProgress" },
  { label: "Approved", value: "Approved" },
];
export const columns = [
  {
    header: "S.No",
    cell: ({ row }) => {
      return <span>{row.index + 1}</span>;
    },
  },

  {
    accessorKey: "status",
    header: "Status",
    cell: ({ row }) => {
      const status = row.original?.status?.trim();
      
      // If status is Completed, show alert flow, otherwise show direct edit modal
      return status === "Completed" ? (
        <EditCompletedStatus row={row} />
      ) : (
        <EditNormalStatus row={row} />
      );
    },
    
  },

  {
    accessorKey: "projectName",
    header: "Project Name",
  },
  {
    accessorKey: "projectStartDate",
    type: "date",
    header: "Start Date",
    cell: ({ row }) => {
      const startDate = row.getValue("projectStartDate");
      return (
        <span className="text-[green]">{startDate?.substring(0, 10)}</span>
      );
    },
  },
  {
    accessorKey: "projectEndDate",
    type: "date",
    header: "End Date",
    cell: ({ row }) => {
      const endDate = row.getValue("projectEndDate");
      return <span className="text-[blue]">{endDate?.substring(0, 10)}</span>;
    },
  },
  {
    cell: ({ row }) =>
      row?.original?.projectDescription?.length > 14 ? (
        <DescriptionModal
          trigger={
            <span className="flex w-[100px] overflow-hidden truncate">{`${row?.original?.projectDescription?.substring(
              0,
              14
            )}....`}</span>
          }
          data={row?.original?.projectDescription}
        />
      ) : (
        row?.original?.projectDescription
      ),

    header: "Description",
  },
  {
    // accessorKey: "createdAt",
    cell: ({ row }) => moment(row.original.createdAt).format("YYYY-MM-DD"),
    header: "Project Added at",
  },
  {
    header: "Project Details Added",
    accessorKey: "detailsAdded",
  },

  // {
  //   header: "Add Employees",
  //   cell: ({ row }) => {
  //     return (
  //       <Link to={`/addEmployee/?projectId=${row.original._id}`}>
  //         <button className="button-17">Add Employees</button>
  //       </Link>
  //     );
  //   },
  // },

  {
    accessorKey: "edit",
    header: "Edit",
    cell: ({ row }) => {
      return (
        <span className="flex justify-center">
          <Link to={`/editProject/${row.original._id}`}>
            <RiEditBoxFill className="text-lg" />
          </Link>
        </span>
      );
    },
  },

  {
    accessorKey: "details",
    header: "Details",
    cell: ({ row }) => {
      return (
        <Link to={`/ProjectDetails/${row.original._id}`}>
          <button className="button-17 !rounded-[10px]">Details</button>
        </Link>
      );
    },
  },
  {
    header: "Add Comment",
    cell: ({ row }) => (
      <AddCommentModal
        id={row.original._id}
        url={"/addProjectCommentByAdmin"}
      />
    ),
  },
  {
    header: "Comment",

    cell: ({ row }) => {
      return (
        <CommentDialog id={row.original._id} url={"/getProjectComments"} />
      );
    },
  },
];

export const TaskColoum = [
  {
    header: "S.No",
    cell: ({ row }) => {
      return <span>{row.index + 1}</span>;
    },
  },

  {
    header: "Department",
    cell: ({row})=>row.original?.employeeId?.teamId?.teamName,
  },
  {
    header: "Employee",
    cell: ({row})=>row.original?.employeeId?.name,
  },
  {
    header: "Start Date",
    cell: ({row})=>moment.utc(row.original?.start_date).format('DD-MM-YYYY'),
    // accessorKey: "start_date",
  },
  {
    header: "End date",
    cell: ({row})=>moment.utc(row.original?.end_date).format('DD-MM-YYYY')
  },
  {
    header: "Status",
    accessorKey: "status",
  },
];

export const ProjectDetailColoum = [
 
  {
    header: "S.No",
    cell: ({ row }) => {
      return <span>{row.index + 1}</span>;
    },
  },
// {
//     header: "Team",
//     cell: ({ row }) => {
//       return <span>{row.original?.}</span>;
//     },
//   },
  
  {
    header: "Project Name",
    // accessorKey:'projectName'
    cell: ({ row }) => {
      return <span>{row.original?.projectName}</span>;
    },
  },
  {
    header: "Client Name",
    // accessorKey: "clientName",
    cell: ({ row }) => {
      return <span>{row.original?.clientName?.name}</span>;
    },
  },
  {
    header: "Project Module",
    // accessorKey: "clientName",
    cell: ({ row }) => {
      return <span>{row.original?.projectModules?.map((item)=><div>{item}</div>)}</span>;
    },
  },
  {
    header: " Project Start Date",
    cell: ({ row }) => {
      return moment(row.original?.projectStartDate).format('DD-MM-YYY');
    },
  },
  {
    header: " Project End date",
    // accessorKey: "projectEndDate",
    cell: ({ row }) => {
      return moment(row.original?.projectEndDate).format('DD-MM-YYY');
    },
  },
  {
    header: "Marketing Team",
    // accessorKey: "Status",
    cell: ({ row }) => {
      return <span>{row.original?.marketingTeam?.leaderId?.name}</span>;
    },
  },
  {
    header: "Marketing Team Member",
    cell: ({ row }) => {
      return <span>{row.original?.marketingTeam?.employees[0]?.employeeId?.name}</span>;
    },
  },
  {
    header: "Manager",
    cell: ({ row }) => {
      return <span>{row.original?.managerTeam?.leaderId?.name}</span>;
    },
  },
  {
    header: "Manager Team Member",
    cell: ({ row }) => {
      return <span>{row.original?.managerTeam?.employees[0]?.employeeId?.name}</span>;
    },
  },
  {
    header: "UI/UX Leader",
    cell: ({ row }) => {
      return <span>{row.original?.designTeam?.leaderId?.name}</span>;
    },
  },
  {
    header: "UI/UX Member",
    cell: ({ row }) => {
      return <span>{row.original?.designTeam?.employees[0]?.employeeId?.name}</span>;
    },
  },
  {
    header: "Tester",
    cell: ({ row }) => {
      return <span>{row.original?.testerTeam?.leaderId?.name}</span>;
    },
  },
  {
    header: "Tester Team Member",
    cell: ({ row }) => {
      return <span>{row.original?.testerTeam?.employees[0]?.employeeId?.name}</span>;
    },
  },
  {
    header: "Backend Leader",
    cell: ({ row }) => {
      return <span>{row.original?.backendTeam?.leaderId?.name}</span>;
    },
  },
  {
    header: "Backend Team Member",
    cell: ({ row }) => {
      return <span>{row.original?.backendTeam?.employees[0]?.employeeId?.name}</span>;
    },
  },
  {
    header: "Frontend Leader",
    cell: ({ row }) => {
      return <span>{row.original?.frontendTeam?.leaderId?.name}</span>;
    },
  },
  {
    header: "Frontend Team Member",
    cell: ({ row }) => {
      return <span>{row.original?.frontendTeam?.employees[0]?.employeeId?.name}</span>;
    },
  },
  {
    header: "View Documents",
    cell: ({ row }) => {
      return <span>{row.original?.marketingTeam?.leaderId?.name}</span>;
    },
  },
];
