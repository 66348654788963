import React, { useMemo } from 'react'
import { getFileExtension } from '../../../PageResponsibility/src/utils/lib';
import { PreviewDocument } from '../../../PageResponsibility/src/admin/pages/projects/PreviewUploadedDocumentsDialog';
import { useLocation, useNavigate } from 'react-router-dom';
import { DataTable } from '../../../PageResponsibility/src/admin/components/table/Data-Table';
// import fetch from 'node-fetch';
import axios from 'axios';
const MyDocuments = () => {
    
  
const location=useLocation()
    const PreviewColumns = useMemo(
        () => [
          {
            header: "S.No",
            cell: ({ row }) => {
              return <span>{row.index + 1}</span>;
            },
          },
          {
            header: "Attched Document",
            cell: ({ row }) => row?.original?.file_name,
          },
      
          {
            accessorKey: "Attcahed Document Type",
            cell: ({ row }) => getFileExtension(row?.original?.url),
          },
          // {
          //   header: "Assign Module",
          //   cell: ({ row }) => <AssignModule file={row?.original?.url} previousModules={row?.original?.module_type}/>,
          // },
      
          {
            header: "Preview",
            cell: ({ row }) => (
              <PreviewDocument
                fileType={row?.original?.url}
                path={row?.original?.url}
              />
            ),
          },
          {
            header: "Actions",
            cell: ({ row }) => <HandleDownload url={row?.original?.url} fileName={ row?.original?.file_name}/>
          },
        ],
        [location?.state?.documents]
      );
  return (
    <DataTable
    columns={PreviewColumns}
    data={location?.state?.documents || []}
    defaultTheme={true}
    TableHeight={location?.state?.documents >0?'80%':'250px'}
    heading="My Documents List"
  />
  )
}

export default MyDocuments


// export const HandleDownload = ({ url, fileName }) => {
//   const navigate = useNavigate();

//   const handleDownload = (e) => {
//     e.preventDefault(); // Prevent the default anchor behavior

//     // Fetch the file from the provided URL
//     fetch(url)
//       .then((response) => {
//         if (!response.ok) {
//           throw new Error('Network response was not ok');
//         }
//         return response.blob();
//       })
//       .then((blob) => {
//         const blobUrl = window.URL.createObjectURL(blob); // Create a URL for the blob
//         const link = document.createElement('a'); // Create a new anchor element
//         link.href = blobUrl;
//         link.download = fileName || 'downloaded-file'; // Use passed fileName or default
//         document.body.appendChild(link);
//         link.click(); // Trigger the download
//         document.body.removeChild(link); // Clean up the DOM
//         window.URL.revokeObjectURL(blobUrl); // Revoke the blob URL after download
//       })
//       .catch((error) => {
//         console.error('Error fetching the file:', error); // Log the error
//       });
//   };

//   // Render the download link
//   return (
//     <a href="#" onClick={handleDownload} className="text-blue-500 hover:underline">
//       Download
//     </a>
//   );
// };

const HandleDownload = ({  url, fileName }) => {
    const navigate=useNavigate()
    
      // const handleDownload = (e) => {
      //   e.preventDefault();
    
      //   fetch(url)
      //     .then((response) => response.blob())
      //     .then((blob) => {
      //       const blobUrl = window.URL.createObjectURL(blob); // No need to wrap in new Blob
      //       const link = document.createElement("a");
      //       link.href = blobUrl;
      //       link.download = fileName || "downloaded-file"; // Use passed fileName or default
      //       document.body.appendChild(link);
      //       link.click();
      //       document.body.removeChild(link);
      //       window.URL.revokeObjectURL(blobUrl);
      //     })
      //     .catch((error) => {
      //       console.error("Error fetching the file:", error);
      //     });
      // };
      const handleDownload = (e) => {
        e.preventDefault();
      axios({
        url: url,
        method: 'GET',
        responseType: 'blob',
      }).then((response) => {
        const blob = new Blob([response.data]);
        const blobUrl = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = blobUrl;
        link.download = fileName || 'downloaded-file';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(blobUrl);
      }).catch((error) => {
        console.error('Error fetching the file:', error);
      });
    };
      const documents = (
        <a
          key={''}
          href="#"
          onClick={(e) => handleDownload(e, url, fileName)} // Pass fileName
          className="text-blue-500 hover:underline"
        >
          DownLoad
        </a>
      );
    
      return documents
    };