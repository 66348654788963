import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import useReportHook from "../../hooks/ReportHook";
import { Button } from "../../components/ui/button";
import ReactSelect from "react-select";
import { Trash } from "lucide-react";
import { useLocation } from "react-router-dom";
import useUserProjectStore from "../../store/userProject";
import useUserHook from "../../hooks/userHook";
import useMyTaskHook from "../../hooks/UseMYtaskHook";
export const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "60%",
    backgroundColor: "#fff",
  },
};

export function EditReportModalUser(props) {
  const [projectsList, setProjectsList] = useState([]);
  const location = useLocation();
  const [reportType,setReportType]=useState()
  const { register, handleSubmit, control, formState, setValue } = useForm();
  const { fields, append, prepend, remove, swap, move, insert, replace } =
    useFieldArray({
      control,
      name: "reports",
    });
  const { errors } = formState;
  const { userTotalProjects:projects } = useUserHook();
  const[taskList,setTasksList]=useState([])
  const{myTasks}=useMyTaskHook()

  useEffect(() => {
    const options = projects?.map((item) => ({
      label: item.projectName,
      value: item._id,
    }));
    setProjectsList(options);
  }, [ projects]);
  useEffect(() => {
    const options = myTasks?.map((item) => ({
      label: item.task,
      value: item._id,
    }));
    setTasksList(options);
  }, [myTasks]);
  useEffect(() => {
    const reportData = location?.state?.data || [];
    const reportType=location?.state?.reportType;
    setReportType(reportType)
    const formattedReportData = reportData?.map((report) => {
      const baseData = {
        report: report.report,
        _id: report._id
      };
  
      const id = report?.project?._id || report?.task?._id;
      const name = report?.project?.projectName || report?.task?.task;
  
   
      return {
        ...baseData,
        [reportType === 'task' ? 'task' : 'project']: {
          value: id,
          label: name
        }
      };
    });
  console.log(formattedReportData,'formattedReportData')
    // Set the formatted data in the form
    setValue("reports", formattedReportData);
  }, [location?.state?.data, location?.state?.reportType, setValue]);
  const {
    editReportUserMutation,
    addReportUserMutation,
    editAccessReportUserMutation,
  } = useReportHook();

  const onSubmit = (data) => {
    const reportData = data?.reports?.map((item, index) => ({
      report: item.report,
      project: item.project?.value,
      task:item.task?.value,
      reportId: location?.state?.id,
    }));
    const AccesData = {  id: location?.state?.id,reports:[ ...reportData],
   
    }
    
    // location?.state?.access
      editAccessReportUserMutation.mutate(AccesData)
      // : addReportUserMutation.mutate(reportData);

    // props.closeEditReportModal();
  };
  

  return (
    <>
      <h2 style={{ color: "#085394" }}>Edit Report</h2>
      <div className="modal-container">
        <div className="modal-body">
          <form
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "10px",
            }}
            onSubmit={handleSubmit(onSubmit)}
          >
            {fields.map((item, index) => (
              <div key={item.id} className="form-row">
                 {reportType ==='task'?
                 <div className="form-group">
                        <Controller
                          rules={{
                            required: "Please select a Task",
                          }}
                          render={({ field }) => (
                            <ReactSelect
                              onChange={(val) => {
                                return field.onChange(val ? val.value : null);
                              }}
                              value={field.value}
                              // styles={style}
                              isClearable
                              // value={
                              //   taskList?.[index]?.find(
                              //     (option) => option.value === field.value
                              //   ) || null
                              // }
                              options={taskList}
                              placeholder={"Select a task ...."}
                              
                            />
                          )}
                          name={`reports.${index}.task`}
                          control={control}
                        />
                        {errors?.reports?.[index]?.task?.message && (
                          <p className="error-message">
                            {" "}
                            {errors?.reports?.[index]?.task?.message}
                          </p>
                        )}
                      </div>: <div className="form-group">
                        <Controller
                          rules={{
                            required: "Please select a Project",
                          }}
                          render={({ field }) => (
                            <ReactSelect
                              onChange={(val) => {
                                return field.onChange(val ? val.value : null);
                              }}
                              value={field.value}
                              // styles={style}
                              isClearable
                              // value={
                              //   teamMembers?.[index]?.find(
                              //     (option) => option.value === field.value
                              //   ) || null
                              // }
                              options={projectsList}
                              placeholder={"Select a Project ...."}
                              
                            />
                          )}
                          name={`reports.${index}.project`}
                          control={control}
                        />
                        {errors?.reports?.[index]?.project?.message && (
                          <p className="error-message">
                            {" "}
                            {errors?.reports?.[index]?.project?.message}
                          </p>
                        )}
                      </div>}
                <textarea
                  rows="10"
                  cols="50"
                  {...register(`reports.${index}.report`, {
                    required: "Please enter your daily report",
                  })}
                />
                {errors?.reports?.[index]?.report?.message && (
                  <p className="error-message">
                    {errors.reports[index].report.message}
                  </p>
                )}
                <button type="button" onClick={() => remove(index)}>
                  <Trash color="red" />
                </button>
              </div>
            ))}
            <button
              type="button"
              onClick={() => append({ project: {}, report: "" })}
              className="flex items-center justify-center mt-[10px] gap-2 mb-[30px]"
            >
              <div
                className="w-[20px] h-[20px] bg-rgb(8 83 148,0.4) flex items-center justify-center rounded-[50%] text-[#eaf2ff]"
                style={{ backgroundColor: "rgb(8,83,148,0.3)" }}
              >
                +
              </div>
              Add Project Report
            </button>
            <Button className="btn btn-primary" type="submit">
              Save
            </Button>
          </form>
        </div>
      </div>
    </>
  );
}
