import { ArrowUpDown } from "lucide-react";
import { CommentDialog } from "../../components/modals/CommentDialog";
import { Button } from "../../components/ui/button";
import { EditClientDialog } from "./EditClientModal";
import moment from "moment";
import { AddCommentModal } from "../reports/AddCommentModal";
import { Link } from "react-router-dom";

export const TruncatedCell = ({ value }) => {
  const truncatedValue = value.slice(0, 15);
  const isValueTruncated = value.length > 15;

  return (
    <span title={isValueTruncated ? value : ""}>
      {truncatedValue}
      {isValueTruncated && "..."}
    </span>
  );
};

export const columns = [
  {
    header: "S.No",
    cell: ({ row }) => {
      return <span>{row.index + 1}</span>;
    },
  },
  {
    accessorKey: "name",
    header: "Name",
  },

  {
    accessorKey: "companyName",
    header: "Company Name",
    cell: ({ getValue }) => <TruncatedCell value={getValue()} />,
  },
  {
    accessorKey: "mobileNumber",
    header: "Mobile No.",
  },
  {
    accessorKey: "email",
    header: "Email",
  },
  {
    accessorKey: "state",
    header: "State",
  },
  {
    accessorKey: "projectName",
    header: "ProjectName",
  },
  {
    accessorKey: "startDate",
    cell: ({ row }) => {
      const dateTime = row.getValue("startDate");
      return moment.utc(dateTime).format("DD-MM-YYYY");
    },
    header: "Start Date",
  },
  {
    accessorKey: "endDate",
    cell: ({ row }) => {
      const dateTime = row.getValue("endDate");
      return moment.utc(dateTime).format("DD-MM-YYYY");
    },
    header: "End Date",
  },
  {
    accessorKey: "amount",
    header: "Amount",
  },
  {
    accessorKey: "amountDue",
    header: "Amount Due",
  },
    {
    // accessorKey: "comments",
    cell: ({ row }) => {
      return <div>
        <Link to='/addProject' >Add Project</Link></div>
    },
    header: " Add Project",
  },
  // {
  //   // accessorKey: "comments",
  //   cell: ({ row }) => {
  //     return <AddCommentModal  id={row.original._id} />;
  //   },
  //   header: " Add Comments",
  // },

  // {
  //   // accessorKey: "comments",
  //   cell: ({ row }) => {
  //     return <CommentDialog data={row.original} />;
  //   },
  //   header: "Comments",
  // },

  {
    header: "Edit",
    cell: ({ row }) => {
      return (
        <div className="">
          <EditClientDialog
            // trigger={<button>Edit</button>}
            clients={row.original}
          />
        </div>
      );
    },
  },
];
