import EditNoteIcon from "@mui/icons-material/EditNote";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import React, { useEffect, useMemo, useState } from "react";
import useReportHook from "../../hooks/ReportHook";
import useUserStore from "../../store/userStore";
import useUserProjectStore from "./../../store/userProject";
import { AddReportUser } from "./AddReportUser";
import { EditReportModalUser } from "./EditReportModalUser";
import { UserAccessReport } from "./UserAccessReport";
import { DataTable } from "../../../PageResponsibility/src/admin/components/table/Data-Table";
import { columns } from "./columns";
import { useNavigate } from "react-router-dom";
import { Button } from "../../../PageResponsibility/src/admin/components/ui/button";
import { CommentDialog } from "../../components/modals/CommentDialog";
import { AddCommentModal } from "../../components/modals/AddCommentModal";
import useUserHook from "../../hooks/userHook";
import { DescriptionModal } from "../../../PageResponsibility/src/admin/components/modals/DiscriptionModal";
import { EditTeamReportModal } from "./EditTeamReport";

export function UserReport() {
  const { data, editReportUserMutation,teamReport } = useReportHook();
  const [userAddReportModalIsOpen, setUserAddReportModalIsOpen] =
    useState(false);
  const [editReport, setEditReport] = useState({});
  const [editReportModalIsOpen, setEditReportModalIsOpen] = useState(false);
  const navigate = useNavigate();
  const openAddReportModalUser = () => {
    setUserAddReportModalIsOpen(true);
  };
  const { user } = useUserStore();
  // const { projects } = ();
  const { userData,userTotalProjects:projects } = useUserHook();
  const [showTeamReport, setShowTeamReport] = useState(false);

  useEffect(() => {
    const exists = Object.keys(userData?.result?.responsibility || {}).includes(
      "Team Reports"
    );
    setShowTeamReport(exists);
    console.log(exists, "existsss");
  }, [userData?.result]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize] = useState(6);

  const totalPages = Math.ceil(data?.length / pageSize);

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const openEditReportModal = (report) => {
    setEditReport(report);
    setEditReportModalIsOpen(true);
  };

  const closeAddReportModalUser = () => {
    setUserAddReportModalIsOpen(false);
  };
  const closeEditReportModal = () => {
    setEditReportModalIsOpen(false);
  };

 
  const usercolumns = useMemo(
    () => [
      { header: "S.No", cell: ({ row }) => row.index + 1 },
      {
        header: "Date",
        cell: ({ row }) => (
          <div className="!w-[150px]">
            {row.original?.date?.substring(0, 10)}
          </div>
        ),
      },
      {
        header: "Report",
        cell: ({ row }) =>
          row.original?.reports?.[0]?.report ? (
            <button
              onClick={() =>
                navigate("/viewReport", {
                  state: {
                    data: row?.original?.reports,
                  },
                })
              }
              style={{
                width: "100px",
                padding: "0",
                fontSize: "11px",
              }}
              className="button-17"
            >
              View Report
            </button>
          ) : (
            "No-reports"
          ),
      },
      {
        header: "Edit",
        cell: ({ row }) =>
          row?.original?.reports?.[0]?.report ? (
            <button
              onClick={() =>
                navigate("/editReport", {
                  state: {
                    data: row?.original?.reports,
                    reportType:row?.original?.reportType,
                    id: row?.original?._id,
                  },
                })
              }
              style={{
                width: "100px",
                padding: "0",
                fontSize: "11px",
              }}
              className="button-17"
            >
              Edit
            </button>
          ) : (
            "No report to edit"
          ),
      },
      {
        header: "Add Comment",
        cell: ({ row }) => (
          <AddCommentModal id={row.original._id} model={"report"} />
        ),
      },
      {
        header: "Comment",

        cell: ({ row }) => {
          return <CommentDialog id={row.original._id} model="report" />;
        },
      },
    ],
    [projects]
  );
  const userTeamcolumns = useMemo(
    () => [
      { header: "S.No", cell: ({ row }) => row.index + 1 },
      {
        header: "Date",
        cell: ({ row }) => (
          <div className="!w-[150px] m-auto">
            {row?.original}
          </div>
        ),
      },
      // {
      //   header: "Report created For",
      //   cell: ({ row }) =>
      //     row.original?.createdFor?.name
           
      // },
      {
        header: "View Report",
        // cell:({row})=>row?.original?.report?.length>14? <DescriptionModal trigger={<span className="flex w-[100px] overflow-hidden truncate">{`${row?.original?.report?.substring(0,14)}....`}</span>} data={row?.original?.report}/>:row?.original?.report
        cell:({row})=>  <button
        onClick={() =>
          navigate("/viewTeamReport", {
            state: {
              data: row?.original,
            },
          })
        }
        style={{
          width: "100px",
          padding: "0",
          fontSize: "11px",
        }}
        className="button-17"
      >
        View Report
      </button>

      },
      // {
      //   header: "Edit",
      //   cell: ({ row }) =><EditTeamReportModal id={row?.original?._id} report={row?.original?.report}/>},
          
      // {
      //   header: "Add Comment",
      //   cell: ({ row }) => (
      //     <AddCommentModal id={row.original._id} model={"report"} />
      //   ),
      // },
      // {
      //   header: "Comment",

      //   cell: ({ row }) => {
      //     return <CommentDialog id={row.original._id} model="report" />;
      //   },
      // },
    ],
    [projects]
  );
  return (
    <>
      <div className="w-full flex justify-between items-center">
        <Button className="mb-4 mr-20" onClick={() => navigate("/addReport")}>
          Add Report{" "}
          <EditNoteIcon
            style={{
              cursor: "pointer",
              color: "white",
              fontSize: "24px",
              marginLeft: "10px",
            }}
          />
        </Button>
        {showTeamReport && (
          <Button
            className="mb-4 ml-auto"
            onClick={() => navigate("/addTeamReport")}
          >
            Add Team Report{" "}
            <EditNoteIcon
              style={{
                cursor: "pointer",
                color: "white",
                fontSize: "24px",
                marginLeft: "10px",
              }}
            />
          </Button>
        )}
      </div>
      <div>
        <DataTable
          data={data || []}
          columns={usercolumns}
          heading={"Recent Reports"}
          defaultTheme={true}
          TableHeight={"400px"}
        />
{showTeamReport && (
         <DataTable
         data={teamReport||[]}
         columns={userTeamcolumns}
         heading="Team Wise Reports "
         defaultTheme={true}
         TableHeight={teamReport?.length ? "400px" : "250px"}
       />
        )}
        <DataTable
          data={projects||[]}
          columns={columns}
          heading="Project Wise Reports "
          defaultTheme={true}
          TableHeight={projects?.length ? "400px" : "250px"}
        />

        <UserAccessReport rights={user?.rights} />
      </div>
    </>
  );
}
