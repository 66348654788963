import PermIdentityIcon from "@mui/icons-material/PermIdentity";
import React, { useEffect, useMemo, useState } from "react";
import { EmpForTheMonth } from "../../components/infoCard/EmpForTheMonth";
import { DataTable } from "../../components/table/Data-Table";
import useEmployeeHook from "../../hooks/useEmployeeHook";
import useEmployeeStore from "../../store/employeeStore";
import Loading from "../protectedPages/Loading";
import { AddEmployeeModal } from "../employee/AddEmployeeModal";
import { EmployeeList } from "./EmployeeList";
import { ResponsibilityModal } from "./ResponsibilityModal";
import {
  DepartmentColumns,
  History,
  ResponsibilityColumns,
  acessReportsColumn,
  columns,
} from "./columns";
import { baseUrl } from "../../config/baseUrl";
import axios from "../../config/axiosAuth";
import EditNoteIcon from "@mui/icons-material/EditNote";
import { AddTeamModal } from "./TeamModal";
import { ResponsibiltyTable } from "../../components/table/ResponsibiltyTable";
import { PiMagnifyingGlassThin } from "react-icons/pi";
import { EditTeamModal } from "./editTeamModal";
import { FaTrash } from "react-icons/fa";
import { useQueryClient } from "react-query";
import { useMediaQuery } from "@mui/material";
import { TeamMemberDetails } from "./TeamMemberDtails";
import { AddDesignation } from "./Designationmodal";
import { useNavigate } from "react-router-dom";
import { Button } from "../../components/ui/button";
import { months } from "../../../utils/lib";

export const customStyles = {
  overlay: {
    zIndex: 20,
  },
  content: {
    top: "0",
    left: "auto",
    right: "0",
    bottom: "0",
    marginRight: "0",
    transform: "none",
    width: "40%",
    height: "100%",
  },
};

const Team = () => {
  const [getAccessList, setgetAccessList] = useState([]);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [teamModal, setTeamModal] = useState(false);

  const [getResponsibility, setgetResponsibility] = useState([]);
  const { employees } = useEmployeeStore();

  const [filter, setFilter] = useState({
    employee: "",
    date: "",
  });
  const { addResponsibilitiesMutation, isLoading, departmentList } =
    useEmployeeHook();
  const [isEmployeeListOpen, setIsEmployeeListOpen] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [selectedEmployeeId, setSelectedEmployeeId] = useState(null);
  const [searchEmployee, setSearchEmployee] = useState("");
  const [responsibiltyModalisOpen, setResponsibilityModalisOpen] =
    useState(false);
  const [team, setTeam] = useState("");
  const [
    selectedEmployeeResponsibilities,
    setSelectedEmployeeResponsibilities,
  ] = useState([]);

  const params = {
    designation: team,
    name: searchEmployee,
    employeeId: filter.employee,
    date: filter.date,
  };
  const {
    data: employeesData,
    isLoading: employeeLoading,
    EmployeeOfTheMonthHistory,
  } = useEmployeeHook(params);
  const openModal = () => {
    setModalIsOpen(true);
  };
  const openTeamModal = () => {
    setTeamModal(true);
  };
  const closeTeamModal = () => {
    setTeamModal(false);
  };
  const handleEmployeeChange = (e) => {
    setFilter((prev) => ({ ...prev, employee: e.target.value }));
  };

  const handleDateChange = (e) => {
    setFilter((prev) => ({ ...prev, date: e.target.value }));
  };
  const closeModal = () => {
    setModalIsOpen(false);
  };

  const handleAccessreport = () => {
    axios
      .post(baseUrl + "/getAccessedList", {})
      .then((res) => {
        setgetAccessList(res.data.result);
      })

      .catch((err) => {});
  };

  useEffect(() => {
    handleAccessreport();
  }, []);

  const handleResponsibility = () => {
    axios
      .get(baseUrl + "/getResponsibility", {})
      .then((res) => {
        setgetResponsibility(res.data.result);
      })

      .catch((err) => {});
  };

  useEffect(() => {
    handleResponsibility();
  }, []);
  const responsibilities = [
    { id: 1, name: "Dashboards", options: [], checked: false },
    { id: 2, name: "Clients", options: [], checked: false },
    { id: 3, name: "Projects", options: [], checked: false },
    { id: 4, name: "Teams", options: [], checked: false },
    { id: 5, name: "Testers", options: [], checked: false },
    { id: 6, name: "Leads", options: [], checked: false },
    { id: 7, name: "Leaves", options: [], checked: false },
    { id: 8, name: "Reports", options: [], checked: false },
    { id: 9, name: "Attendances", options: [], checked: false },

    {
      id: 10,
      name: "Hirings",
      options: [],
      checked: false,
    },
    {
      id: 11,
      name: "Followups",
      options: [],
      checked: false,
    },
    {
      id: 12,
      name: "Payrolls",
      options: [],
      checked: false,
    },
    {
      id: 13,
      name: "Screen Sharings",
      options: [],
      checked: false,
    },
    { id: 14, name: "Detailed timeSheet", options: [], checked: false },
    { id: 15, name: "Team Reports", options: [], checked: false },
    { id: 16, name: "Allocations", options: [], checked: false },
    { id: 17, name: "Assign Tasks", options: [], checked: false },

  ];
  const queryClient = useQueryClient();
  const DeleteDepartmentList = async (id) => {
    try {
      const response = await axios.delete(`${baseUrl}/deleteTeam/${id}`);
      queryClient.invalidateQueries("departmentList");
      // toast.success(response.data.message)
    } catch (err) {
      // toast.error(err.response.data.message)
    }
  };

  const openEmployeeList = () => {
    setIsEmployeeListOpen(true);
  };

  const handleResponsibiltyModalOpen = () => {
    setResponsibilityModalisOpen(true);
  };

  const handleSearch = (event) => {
    setSearchText(event.target.value);
  };

  const handleEmployeeSelect = (employeeId) => {
    setSelectedEmployeeId(employeeId);
    setIsEmployeeListOpen(false);

    handleResponsibiltyModalOpen();
  };

  const filteredEmployees = employees.filter((employee) => {
    return employee.name.toLowerCase().includes(searchText.toLowerCase());
  });

  const handleAddResponsibilities = (selectedOptions) => {
    const transformedData = Object.entries(selectedOptions)
      .filter(([_, value]) => value.length > 0)
      .map(([key, value]) => ({
        responsibilityName: key,
        actions: value,
      }));
    addResponsibilitiesMutation.mutate({
      employeeId: selectedEmployeeId,
      responsibility: selectedOptions,
    });
    setResponsibilityModalisOpen(false);
  };

  // if (isLoading) {
  //   return <Loading />;
  // }
  const navigate = useNavigate();

  const handleChangeTeam = (e) => {
    setTeam(e.target.value);
  };
  return (
    <>
      <>
        <section
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <EmpForTheMonth />
        </section>

        <div
          onClick={openEmployeeList}
          className="flex justify-end gap-2 text-xs"
        >
          <div
            style={{
              border: "1px solid black",
              borderRadius: "10px",
              backgroundColor: "white",
              cursor: "pointer",
              padding: "3px",
            }}
          >
            <PermIdentityIcon />
            <span>Add Responsibilty</span>
          </div>
        </div>
        <div className="flex flex-col gap-2 w-[150px]">
          <AddTeamModal
            isOpen={teamModal}
            closeModal={() => setTeamModal(false)}
          />
          <AddDesignation />
          <Button
            className="hover:bg-[#085394]"
            onClick={() => navigate("/DeletedUsers")}
          >
            Deleted Users
          </Button>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            marginTop: "20px",
          }}
        >
          <button
            onClick={openModal}
            style={{
              borderRadius: "5px",
              padding: "5px",
              backgroundColor: "#085394",
              color: "white",
              marginBottom: "10px",
            }}
          >
            Add Employee
          </button>
        </div>
        <AddEmployeeModal isOpen={modalIsOpen} closeModal={closeModal} />
        <div className="flex gap-4 items-end mb-[10px]">
          <div className=" h-[15%] max-w-[250px] p-2   bg-white  rounded-md">
            <select
              onChange={handleEmployeeChange}
              className="h-full w-full border-0 rounded-lg  text-[13px]"
              name=""
              id=""
              value={filter.employee}
            >
              <option value="" className="text-sm ">
                Select Employee
              </option>
              {employees && employees?.length > 0
                ? employees?.map((employee) => (
                    <option value={employee._id} className="text-sm ">
                      {employee?.name}
                    </option>
                  ))
                : null}
            </select>
          </div>
          <div className=" h-[15%] max-w-[250px] p-2   bg-white  rounded-md">
            <select
              onChange={handleDateChange}
              className="h-full w-full border-0 rounded-lg  text-[13px]"
              name=""
              id=""
              value={filter.date}
            >
              <option value="" className="text-sm ">
                Select Month
              </option>
              {months && months?.length > 0
                ? months?.map((employee) => (
                    <option value={employee.value} className="text-sm ">
                      {employee?.label}
                    </option>
                  ))
                : null}
            </select>
          </div>
        </div>
        <DataTable
          defaultTheme={true}
          columns={History}
          data={EmployeeOfTheMonthHistory || []}
          employee={true}
          showSearchBar={true}
          showColumns={true}
          showExport={true}
          TableHeight={
            EmployeeOfTheMonthHistory && EmployeeOfTheMonthHistory.length > 0
              ? "400px"
              : ""
          }
          heading={"Employee of the Month History"}
        />
        <div className="flex gap-4 items-end mb-[10px]">
          <div className=" h-[15%] max-w-[250px] p-2   bg-white  rounded-md">
            <select
              onChange={handleChangeTeam}
              className="h-full w-full border-0 rounded-lg  text-[13px]"
              name=""
              id=""
              value={team}
            >
              <option value="" className="text-sm ">
                Select Department
              </option>
              {departmentList && departmentList?.length > 0
                ? departmentList?.map((department) => (
                    <option value={department._id} className="text-sm ">
                      {department?.teamName}
                    </option>
                  ))
                : null}
            </select>
          </div>
          <div className="h-[15%] max-w-[250px]">
            <div className="search_icon  ">
              <PiMagnifyingGlassThin />
              <input
                placeholder="Search"
                onChange={(e) => setSearchEmployee(e.target.value)}
                className="p-2 h-full"
              />
            </div>
          </div>
        </div>
        <DataTable
          defaultTheme={true}
          columns={columns}
          data={employeesData || []}
          employee={true}
          showSearchBar={true}
          showColumns={true}
          showExport={true}
          TableHeight={employeesData && employeesData.length > 0 ? "400px" : ""}
          heading={"All Employee"}
        />
        <span className="mt-5">{/* <Employee /> */}</span>

        <div className=" h-[60vh] bg-white w-[100%] rounded-[5px] overflow-y-hidden mt-3">
          <DataTable
            className={"h-[65%]"}
            columns={DepartmentColumns}
            data={departmentList || []}
            heading={"Department List"}
            height={"100%"}
            defaultTheme={true}
            tableContainerProps={"h-[100%] overflow-auto"}
            // TableHeight={'300px'}
          />
        </div>

        <div className="flex gap-5 w-full flex-col  mt-[50px] mb-[30px]">
          <>
            <div className=" h-[40vh] bg-white w-[100%] rounded-[5px] overflow-y-hidden p-2">
              <DataTable
                className={"h-[70%]"}
                columns={ResponsibilityColumns}
                data={getResponsibility || []}
                heading={"RESPONSIBILITY"}
                height={"100%"}
                tableContainerProps={"h-[100%] overflow-auto"}
              />
            </div>
          </>

          <>
            <div className="h-[40vh] bg-white w-[100%]  rounded-[5px] overflow-y-hidden p-2">
              <DataTable
                columns={acessReportsColumn}
                data={getAccessList || []}
                height={"100%"}
                heading={"ACCESS REPORTS"}
                className={"h-[70%]"}
                tableContainerProps={"h-[100%] overflow-auto"}
              />
            </div>
            {/* </div> */}
          </>
        </div>
      </>

      <EmployeeList
        isEmployeeListOpen={isEmployeeListOpen}
        setIsEmployeeListOpen={setIsEmployeeListOpen}
        searchText={searchText}
        handleSearch={handleSearch}
        handleEmployeeSelect={handleEmployeeSelect}
        filteredEmployees={filteredEmployees}
      ></EmployeeList>

      <ResponsibilityModal
        isOpen={responsibiltyModalisOpen}
        closeModal={() => setResponsibilityModalisOpen(false)}
        responsibilities={responsibilities}
        setSelectedEmployeeResponsibilities={
          setSelectedEmployeeResponsibilities
        }
        selectedEmployeeId={selectedEmployeeId}
        handleAddResponsibilities={handleAddResponsibilities}
        selectedEmployeeResponsibilities={selectedEmployeeResponsibilities}
      />
    </>
  );
};

export default Team;
