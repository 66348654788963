import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import img from "../../assets/star.gif";
import { baseUrlCommon } from "../../config/baseUrl";
import empForTheMothStore from "../../store/empForTheMonth";
import axios from '../../../PageResponsibility/src/admin/config/axiosAuth'
import useUserHook from "../../hooks/userHook";
export const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    transform: "translate(-50%, -50%)",
    width: "300px",
    textAlign: "center",
  },
};

Modal.setAppElement("#root");

// export const EmpForTheMonth = ({ isEmployeeOfTheMonth }) => {
//   const { employeeForTheMonth, setEmployeeForTheMonth } = empForTheMothStore();
//   const[showStars,setShowStars]=useState(false)

//   const {user}=useUserHook()
//   useEffect(()=>{
//     if(
//     employeeForTheMonth?._id===user?._id ){
//       setShowStars(true)
//     }else{
//       setShowStars(false)
//     }
//   },[employeeForTheMonth,user])
//   const getEmpMonth = async () => {
//     const response = await axios.get(baseUrlCommon + "/getEmpMonth");
//     const emp = response.data.result.employeeId;
//     setEmployeeForTheMonth(emp);
//   };

//   useEffect(() => {
//     getEmpMonth();
//   }, []);

//   return (
//     <div
//       className="todo-list"
//       style={{
//         width: "auto",
//         minWidth:'300px',
//         maxWidth:'450px',
//         maxHeight:'300px'
//       }}
//     >
//       <div
//         className="header"
//         style={{
//           display: "flex",
//           justifyContent: "space-between",
//         }}
//       >
//         <div className="header-text">Employee of the Month</div>

//         {showStars&& (
//           <div
//             style={{
//               display: "flex",
//               justifyContent: "space-between",
//               alignItems: "center",
//               gap: "10px",
//               width: "250px",
//             }}
//           >
//             <img src={img} style={{ width: "50px" }} alt="" />
//             <img src={img} style={{ width: "50px" }} alt="" />
//             <img src={img} style={{ width: "50px" }} alt="" />
//           </div>
//         )}
//       </div>
//       <div className="divider"></div>
//       <div style={{ display: "flex" }}>
//         <img
//           alt="emp"
//           style={{
//             width: "200px",
//           }}
//           className="empImg"
//           src="https://img.freepik.com/premium-vector/hr-employee-performance-evaluation-work-improvement-career-growth-efficiency-personnel_566886-1966.jpg?w=300"
//         />
//         <div
//           style={{
//             overflowWrap: "anywhere",
//           }}
//         >
//           <div style={{ fontWeight: "bold" }}>{employeeForTheMonth?.name}</div>
//           <div>{employeeForTheMonth?.designation?.name}</div>
//         </div>
//       </div>
//       {/* {isEmployeeOfTheMonth && <StarAnimation />} */}
//     </div>
//   );
// };
export const EmpForTheMonth = ({ isEmployeeOfTheMonth }) => {
  const { employeeForTheMonth, setEmployeeForTheMonth } = empForTheMothStore();
  const [showStars, setShowStars] = useState(false);
  const [loading, setLoading] = useState(true);
  const { user } = useUserHook();

  const getEmpMonth = async () => {
    try {
      setLoading(true);
      const response = await axios.get(baseUrlCommon + "/getEmpMonth");
      const emp = response.data.result.employeeId;
      setEmployeeForTheMonth(emp);
      setShowStars(emp?._id === user?._id);
    } catch (error) {
      console.error("Error fetching employee of the month:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getEmpMonth();
  }, [user]);

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div
      className="todo-list"
      style={{
        width: "auto",
        minWidth: '300px',
        maxWidth: '450px',
        maxHeight: '300px'
      }}
    >
      <div
        className="header"
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <div className="header-text">Employee of the Month</div>
        
        {showStars && (
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              gap: "10px",
              width: "250px",
            }}
          >
            <img src={img} style={{ width: "50px" }} alt="" />
            <img src={img} style={{ width: "50px" }} alt="" />
            <img src={img} style={{ width: "50px" }} alt="" />
          </div>
        )}
      </div>
      <div className="divider"></div>
      <div style={{ display: "flex" }}>
        <img
          alt="emp"
          style={{
            width: "200px",
          }}
          className="empImg"
          src="https://img.freepik.com/premium-vector/hr-employee-performance-evaluation-work-improvement-career-growth-efficiency-personnel_566886-1966.jpg?w=300"
        />
        <div
          style={{
            overflowWrap: "anywhere",
          }}
        >
          <div style={{ fontWeight: "bold" }}>{employeeForTheMonth?.name}</div>
          <div>{employeeForTheMonth?.designation?.name}</div>
        </div>
      </div>
    </div>
  );
};