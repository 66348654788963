import { useMutation, useQuery, useQueryClient } from "react-query";
import { toast } from "react-toastify";
import useUserProjectStore from "../store/userProject";
import useUserStore from "../store/userStore";
import { userService } from "../services/userService";

const useUserHook = (params = {}) => {
  const {
    totalProjects,
    setProjects,
    setTotalProjects,
    totalCompletedProjects,
    setTotalCompletedProjects,
    completedProjects,
    setCompletedProjects,
    pendingProjects,
    setPendingProjects,
    setTotalPendingProjects,
  } = useUserProjectStore();

  const { user, setUser } = useUserStore();
  const queryClient = useQueryClient();

  const fetchUser = async () => {
    const res = await userService.getUserDetails();
    return res;
  };

  const fetchUserLeaves = async () => {
    const res = await userService.getUserLeaves(params);
    return res;
  };
  const fetchUserApprovedLeaves = async () => {
    const res = await userService.getUserApprovedLeaves();
    return res;
  };
  const EditUserProfileMutation = useMutation(
    (data) => userService.updateProfile(data),
    {
      onSuccess: (data) => {
        queryClient.invalidateQueries("user");
      },
    }
  );

  const fetchUserTotalProjects = async () => {
    const res = await userService.getUserTotalProjects(params.date);
    return res.data.result;
  };

  const fetchUserCompletedProjects = async () => {
    const res = await userService.getUserCompletedProjects();
    return res;
  };

  const fetchUserPendingProjects = async () => {
    const res = await userService.getUserPendingProjects();
    return res;
  };

  const {
    data: userData,
    error: userError,
    isLoading: userLoading,
    isError: userIsError,
  } = useQuery("user", fetchUser, {
    onSuccess: (data) => {
      setUser(data?.result);
    },
    onError: (error) => {},
  });

  // const {
  //   data: userLeaves,
  //   error: userLeavesError,
  //   isLoading: userLeavesLoading,
  //   isError: userLeavesIsError,
  // } = useQuery("userLeaves", ()=>fetchUserLeaves(params));
  const {
    data: userLeaves,
    error: userLeavesError,
    isLoading: userLeavesLoading,
    isError: userLeavesIsError,
  } = useQuery(
    ["userLeaves", params], // add params to the query key to trigger updates
    () => fetchUserLeaves(params),
    {
      enabled: !!params, // only fetch if params are defined
    }
  );
  const { data: userApprovedLeaves } = useQuery(
    "userApprovedLeaves",
    fetchUserApprovedLeaves
  );

  const {
    data: userTotalProjects,
    error: userTotalProjectsError,
    isLoading: userTotalProjectsLoading,
    isError: userTotalProjectsIsError,
  } = useQuery(["userTotalProjects",params.date], ()=>fetchUserTotalProjects(params.date), {
    onSuccess: (data) => {
      setTotalProjects(data?.result?.length);
      setProjects(data?.result);
    },
  });

  const {
    data: userCompletedProjects,
    error: userCompletedProjectsError,
    isLoading: userCompletedProjectsLoading,
    isError: userCompletedProjectsIsError,
  } = useQuery("userCompletedProjects", fetchUserCompletedProjects, {
    onSuccess: (data) => {
      setTotalCompletedProjects(data?.count);
      setCompletedProjects(data?.result);
    },
  });

  const {
    data: userPendingProjects,
    error: userPendingProjectsError,
    isLoading: userPendingProjectsLoading,
    isError: userPendingProjectsIsError,
  } = useQuery("userPendingProjects", fetchUserPendingProjects, {
    onSuccess: (data) => {
      setTotalPendingProjects(data?.count);
      setPendingProjects(data?.result);
    },
    enabled: !!params,
  });

  const editProjectMutation = useMutation(
    (data) => userService.editProjectStatus(data.id, data.action),
    {
      onSuccess: (data) => {
        setUser(data?.result);
      },
    }
  );

  const updateDeviceTokenMutation = useMutation(
    (data) => userService.updateDeviceToken(data),
    {
      onSuccess: (data) => {},
    }
  );

  const applyLeaveMutation = useMutation(
    (data) => {
      return userService.applyLeave(data);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("userLeaves");
      },
    }
  );

  return {
    userData,
    userError,
    userLoading,
    userIsError,
    userLeaves,
    userLeavesError,
    userLeavesLoading,
    userLeavesIsError,
    userTotalProjects,
    userTotalProjectsError,
    userTotalProjectsLoading,
    userTotalProjectsIsError,
    userCompletedProjects,
    userCompletedProjectsError,
    userCompletedProjectsLoading,
    userCompletedProjectsIsError,
    totalProjects,
    totalCompletedProjects,
    completedProjects,
    user,
    editProjectMutation,
    applyLeaveMutation,
    userPendingProjects,
    userPendingProjectsError,
    userPendingProjectsLoading,
    userPendingProjectsIsError,
    pendingProjects,
    updateDeviceTokenMutation,
    EditUserProfileMutation,
    userApprovedLeaves,
  };
};

export default useUserHook;
