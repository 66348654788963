import UserProjects from "./user/pages/projects/UserProjects";
import UserProfile from "./user/pages/profile/UserProfile";
import UserLeaves from "./user/pages/Leaves/UserLeaves";
import { UserReport } from "./user/pages/reports/UserReport";
import UserNotification from "./user/pages/notification/userNotification";
import ForgotPassUser from "./user/pages/login/ForgotPassUser";
import UserDashboard from "./user/pages/Dashboard/UserDashboard";
// import Client from "./user/pages/Client/Client";
import { TaskManagement } from "./user/pages/TaskMangement/UserTaskmanagement";
import UserAttendance from "./user/pages/attendance/Attendace";
import Meeting from "./user/pages/Meetings";
import MeetingsList from "./user/pages/MeetingsList";

// Resposnibility Routes
// Resposnibility Routes

import {AdminDashboard} from './PageResponsibility/src/admin/pages/Dashboard/AdminDashboard.jsx';
import Client from './PageResponsibility/src/admin/pages/Client/Client.jsx';
import TaskManagment from './PageResponsibility/src/admin/pages/taskmangement/TaskManagement.jsx';
import Timeline from './PageResponsibility/src/admin/pages/projectTimeline/Timeline.jsx';
import Payroll from './PageResponsibility/src/admin/pages/payroll/Payroll.js';
import Team from './PageResponsibility/src/admin/pages/team/Team.jsx';
import OfficeCalendar from './PageResponsibility/src/admin/components/newCalender/NewCalender.js';
import Attendance from './PageResponsibility/src/admin/pages/attendance/Attendace.jsx';
import Project from './PageResponsibility/src/admin/pages/projects/Project.jsx';
import DetailedrojectTesterSheet from './PageResponsibility/src/admin/pages/Testers/DetailedrojectTesterSheet.jsx'
import PresentEmployees from './PageResponsibility/src/admin/pages/Dashboard/PresentEmployees.jsx';
import AbsentEmployees from './PageResponsibility/src/admin/pages/Dashboard/AbsentEmployees.jsx';
import AddProjectNew from './PageResponsibility/src/admin/pages/addProject/addProjectNew.jsx';
import EditProjectNew from './PageResponsibility/src/admin/pages/editProjects/EditProjectNew.jsx'
import {AdminReport} from './PageResponsibility/src/admin/pages/reports/AdminReport.js';
import Responsibility from './PageResponsibility/src/admin/pages/Responsibilty/Responsibilty.jsx';
import SendNotification from './PageResponsibility/src/admin/pages/sendNotification/SendNotification.js';
import ListNotification from './PageResponsibility/src/admin/pages/sendNotification/ListNotification.js';
import Hiring from './PageResponsibility/src/admin/pages/Hiring/Hiring.jsx'
import ProjectDetails from './PageResponsibility/src/admin/pages/projects/ProjectDetails.jsx'
import EmployeeListByWorkMode from './PageResponsibility/src/admin/pages/Dashboard/employeeListByWorkMode.jsx'
import EmployeeAdd from './PageResponsibility/src/admin/pages/addProject/addEmployee.jsx'
import BirthdayTable from './PageResponsibility/src/admin/components/table/BirthdayTable.jsx'
 import Testing from './PageResponsibility/src/admin/pages/Testers/Testing.jsx'
 import HolidayTable from './PageResponsibility/src/admin/components/table/HolidayTable.jsx'

 import {LeaveTable} from './PageResponsibility/src/admin/components/table/LeaveTable.jsx'
import TasksByUserId from './PageResponsibility/src/admin/pages/projects/TasksByUserId.jsx'
import Profile from './PageResponsibility/src/admin/pages/profile/Profile.jsx'
 import Leaves from './PageResponsibility/src/admin/pages/Leaves/Leave.jsx'
 import EmployeLeaveList from './PageResponsibility/src/admin/pages/Leaves/EmployeLeaveList.jsx'
 import ReportById from './PageResponsibility/src/admin/pages/reports/ReportById.jsx'
 import Leads from './PageResponsibility/src/admin/pages/Leads/Leads.jsx'
 import {AddTask} from './PageResponsibility/src/admin/pages/projects/AddTask.jsx'
 import MonthlyAttendence from './PageResponsibility/src/admin/pages/attendance/MonthlyAttendence.jsx'
import ProjectCalender from './PageResponsibility/src/admin/pages/ProjectCalender/ProjectCalender.jsx'
  import CompletedProjects from './PageResponsibility/src/admin/pages/Dashboard/CompletedProjects.jsx'
  import {FollowUps} from './PageResponsibility/src/admin/pages/FollowUps/FollowUps.jsx'

 import Meet from './PageResponsibility/src/admin/pages/MeetingTab/index.jsx'
  import Screens from './PageResponsibility/src/admin/pages/screens/Screens.jsx'
import UserOfficeCalendar from "./user/pages/DetailedTimesheet/newCalender/NewCalender.js";
import DetailedUserReport from "./user/pages/reports/DetailedReport.jsx";
import { AddReportUser } from "./user/pages/reports/AddReportUser.js";
import { EditReportModalUser } from "./user/pages/reports/EditReportModalUser.js";
import ViewReport from "./user/pages/reports/ViewReport.jsx";
import { UserAccessReportById } from "./user/pages/reports/UserAccessReportById.jsx";
import EmployeeWiseProjects from "./PageResponsibility/src/admin/pages/team/EmployeeWiseProjects.jsx";
import DeletedUsers from "./PageResponsibility/src/admin/pages/team/DeletedUsers.jsx";
import ProjectHistory from "./PageResponsibility/src/admin/pages/projects/projectHistory.jsx";
import { AddTeamReportUser } from "./user/pages/reports/AddTeamReport.jsx";
import Allocations from "./PageResponsibility/src/admin/pages/Allocations/index.jsx";
import AddAllocation from "./PageResponsibility/src/admin/pages/Allocations/AddAllocations.jsx";
import EditAllocation from "./PageResponsibility/src/admin/pages/Allocations/EditAllocation.jsx";
import LeaderTeam from "./PageResponsibility/src/admin/pages/reports/LeaderTeam.jsx";
import LeaderTeamReport from "./PageResponsibility/src/admin/pages/reports/LeaderTeamReport.jsx";
import ViewTeamReport from "./user/pages/reports/ViewTeamReport.jsx";
import MyDocuments from "./user/pages/projects/MyDocuments.jsx";
import AssignTask from "./PageResponsibility/src/admin/pages/AssignTasks/index.jsx";
import {AddAssignTask} from './PageResponsibility/src/admin/pages/AssignTasks/AddAssignTask.jsx'
import {EditAssignTask} from './PageResponsibility/src/admin/pages/AssignTasks/EditTaskAssignTask.jsx'
import MyTask from "./user/pages/MyTask/index.jsx";

//  i  import DetailedrojectTesterSheet from './PageResponsibility/src/admin/pages/Testers/DetailedrojectTesterSheet.jsx'

export const routes = [
  { path: "/userdashboard", component: UserDashboard },
  { path: "/UserAttendance", component: UserAttendance },
  { path: "/userProjects", component: UserProjects },
  { path: "/userTaskmanagement", component: TaskManagement },
  { path: "/viewTeamReport", component: ViewTeamReport },
  { path: "/myDocs", component: MyDocuments },
  { path: "/my-task", component: MyTask },



  { path: "/userProfile", component: UserProfile },
  { path: "/userLeaves", component: UserLeaves },
  { path: "/userReports", component: UserReport },
  { path: "/userNotification", component: UserNotification },
  { path: "/userMeetings", component: MeetingsList },
  ///////////
  {path:'/userReport/:id',component:DetailedUserReport},
  { path: "/forgotpassword", component: ForgotPassUser },
  { path: "/forgotpassword", component: ForgotPassUser },
  { path: "/userMeet/:id", component: Meeting },
  { path: "/DeletedUsers", component: DeletedUsers },
  { path: "/projectHistory", component: ProjectHistory },
  { path: "/addTeamReport", component: AddTeamReportUser },


  //////Responsibilities

  // { path: "/detailedTimesheet", component: DetailedtimeSheet },
  // { path: "/dashboard", component: dashboard },
  // { path: "/clients", component: Client },
  // { path: "/newHiring", component: Hiring },
  // { path: "/followups", component: FollowUp },
  // { path: "/Leads", component: Leads },
  // { path: "/meet/:id", component: Meeting },
  // { path: "/projects", component: Projects },
  // { path: "/taskmanagement", component: TaskManagements },
  // { path: "/reports", component: Reports },
  // { path: "/leaves", component: Leaves },
  // { path: "/team", component: Team },
  // { path: "/tester", component: Tester },
  // { path: "/payroll", component: Payroll },
  // { path: "/attendance", component: Attendence },
  {
    path: "/dashboard",
    component: AdminDashboard,
  },
  {
    path: "/clients",
    component: Client,
  },
  {
    path: "/employeeProjects/:id",
    component: EmployeeWiseProjects,
  },
  {
    path: "/taskManagement",
    component: TaskManagment,
  },
  {
    path: "/projectTimeline",
    component: Timeline,
  },
  {
    path: "/payroll",
    component: Payroll,
  },
  {
    path: "/team",
    component: Team,
  },
  {
    path: "/userDetailedTimesheet",
    component: UserOfficeCalendar,
  },

  {
    path: "/projectTickets/:id",
    component: DetailedrojectTesterSheet,
  },
  {
    path: "/attendance",
    component: Attendance,
  },
  { path: "/presentEmployees", component: PresentEmployees },
  { path: "/absentEmployees", component: AbsentEmployees },
  {
    path: "/project",
    component: Project,
  },
  {
    path: "/addProject",
    component: AddProjectNew,
  },
{path:'/dashboard-leave',
  component: LeaveTable
},
{path:'/dashboard-holiday',
  component: HolidayTable
},
{path:'/accessReport/:id',
  component:UserAccessReportById
},
{path:'/dashboard-birthday',
  component: BirthdayTable
},
  {
    path: "/addEmployee",
    component: EmployeeAdd,
  },

  {
    path: "/testing",
    component: Testing,
  },
  {
    path: "/allocations",

    component: Allocations,
  },
  { path: "/addAllocation", component: AddAllocation },
  { path: "/editAllocation/:id", component: EditAllocation },
  {
    path: "/editProject/:projectId",
    component:EditProjectNew ,
    // component:EditProject
  },
  {
    path: "/assign-task",
    component: AssignTask,
  },
  {
    path: "/add-assign-task",
    component: AddAssignTask,
  },
  {
    path: "/edit-assign-task/:id",
    component: EditAssignTask,
  },

  {
    path: "/workMode",
    component: EmployeeListByWorkMode,
  },

  {
    path: "/ProjectDetails/:projectId",
    component: ProjectDetails,
  },
  {
    path: "/newHiring",
    component: Hiring,
  },
  {
    path: "/task/:id",
    component: TasksByUserId,
  },
  {
    path: "/sendnotification",
    component: SendNotification,
  },
  {
    path: "/listNotification",
    component: ListNotification,
  },
  {
    path: "/profile",
    component: Profile,
  },
  {path:'/detailedTimesheet',
    component:OfficeCalendar
  },
  {
    path: "/leave",
    component: Leaves,
  },
  {
    path: "/employeeLeaveList/:id",
    component: EmployeLeaveList,
  },
  {
    path: "/monthlyReports/:id",
    component: ReportById,
  },
   {
      path: "/adminReport",
   
      component: AdminReport,
    },

  {
    path: "/responsibility",
    component: Responsibility,
  },
  {
    path: "/hiring-followups",
    component: FollowUps,
  },
  {
    path: "/leads",
    component: Leads,
  },

  {
    path: "/projectCalendar",
    component: ProjectCalender,
  },
  {
    path: "/editReport",
    component: EditReportModalUser,
  },
  {path:'/viewReport',
    component:ViewReport
  }
  ,
  {
    path: "/addTask",
    component: AddTask,
  },
  {
    path: "/Screens",
    component: Screens,
  },
  {
    path: "/meeting",
    component: Meeting,
  },
  {
    path: "/meet/:id",
    component: Meeting,
  },
  {path:'/addReport',
    component:AddReportUser
  },
  {
    path:"/monthlyAttendence/:id",
    component:MonthlyAttendence,
  },
  {
    path:'/completedProjects',
    component:CompletedProjects
  },
  {
    path: "/leaderTeam/:id",
    component: LeaderTeam,
  },
  {
    path: "/leaderTeamReport/:id",
    component: LeaderTeamReport,
  },
  
];
