import { toast } from "react-toastify";
import axios from "../config/axiosAuth";
import { baseUrlCommon, baseUrlUser } from "../config/baseUrl";

const taskServices = {
  getTask: async (id,params) => {
    let url;
    if(params.project!==''&&params.status!==''){
      url=`${baseUrlUser}/getAllTask?projectId=${params.project}&&status=${params.status}`
    }else if(params.project!==''&&params.status===''){
      url=`${baseUrlUser}/getAllTask?projectId=${params.project}`

    }else if(params.project===''&&params.status!==''){
      url=`${baseUrlUser}/getAllTask?status=${params.status}`

    }else if(params.project===''&&params.status===''){
      url=`${baseUrlUser}/getAllTask`

    }else{
 url=`/getAllTask`
    }
    const response = await axios.post(url, {
      employeeId: id,
    });
    return response.data;
  },

  editTask: async (data) => {
    const response = await axios.put(
      baseUrlUser + "/editTask/" + data._id,
      data.data
    );


    return response.data;
  },
};

export default taskServices;
