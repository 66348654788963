import CheckIcon from "@mui/icons-material/Check";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import React, { useState } from "react";
import Modal from "react-modal";
import {
  transformProjectData,
  transformProjectDataToToDoList,
} from "../../../utils/transformData";
import Calendar from "../../components/calender/Calender";
import { ToDoList } from "../../components/infoCard/ToDoList";
import useUserHook from "../../hooks/userHook";
import useUserStore from "../../store/userStore";
import useUserProjectStore from "../../store/userProject";
import { DataTable } from "../../../PageResponsibility/src/admin/components/table/Data-Table";
import { columns } from "./columns";

const styles = {
  Active: {
    color: "green",
  },
  Inactive: {
    color: "orange",
  },
  Progress: {
    color: "red",
  },
};

Modal.setAppElement("#root");

const UserProjects = () => {
  const { user } = useUserStore();
  const userProjects = user?.projects;
  // const { projects } = useUserProjectStore();
  const { editProjectMutation, totalProjects } = useUserHook();
const[dateCLicked,setDateClicked]=useState('')
  const { pendingProjects ,userTotalProjects:projects} = useUserHook({date:dateCLicked});
console.log(dateCLicked,'dateCLicked')
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize] = useState(3);
  const [action, setAction] = useState("");
  const [id, setId] = useState("");
  const pendingList = transformProjectData(projects||[]);
  const toDoList = transformProjectDataToToDoList(projects);
  const totalPages = Math.ceil(userProjects?.length / pageSize);

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handleActionChange = (id, action) => {
    setAction(action);
    setId(id);
  };
  console.log(projects, "userprojectdatas");
  const handleEditStatus = (e) => {
    e.preventDefault();

    editProjectMutation.mutate({ id, action });
  };

  return (
    <>
      <div
        className="project_calendar"
        style={{
          display: "flex",
          gap: "20px",
          marginBottom: "30px",
        }}
      >
        {/* <Calender events={pendingList} /> */}
        <Calendar events={pendingList} setDateClicked={setDateClicked}/>
        <ToDoList project={toDoList} title="Breif about Project" />
      </div>
      <DataTable
        data={projects||[]}
        columns={columns}
        heading="Project List "
        defaultTheme={true}
      />
    </>
  );
};

export default UserProjects;
