import axios from "../config/axiosAuth";
import { baseUrlCommon, baseUrl, baseUrlUser } from "../config/baseUrl";

const myTaskService = {
  getMyTasks: async () => {
    const response = await axios.get(baseUrlUser + "/my-task");
    return response.data.result;
  },

 
};

export default myTaskService;
