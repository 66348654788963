import React, { useEffect, useRef, useState } from "react";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import useEmployeeHook from "../../hooks/useEmployeeHook";
import { Button } from "../../components/ui/button";
import { useLocation, useParams } from "react-router-dom";
import ReactSelect from "react-select";
import { baseUrl } from "../../config/baseUrl";
import axios from "../../config/axiosAuth";
import { SelectCustomStyles } from "../../../utils/styles";
import useAllocationHook from "../../hooks/useAllocationHook";
import { NestedFields } from "./NestedEditAllocations";
import { cn } from "../../../utils/lib";

const EditAllocation = () => {
  const params = useParams();
  const Location=useLocation()
  const id = params.id;
  const { data: employees } = useEmployeeHook({data:true,designation:'',name:''});
  const searchParams = { id: id };
  const { updateAllocationMutation, allocationById } =
    useAllocationHook(searchParams);
  const { departmentList } = useEmployeeHook({departmentList:true});
  const [employeeOptions, setEmployeeOptions] = useState([]);
  const [departmentOptions, setDepartmentOptions] = useState([]);
  const submitRef = useRef(false);
  const [teamMembers, setTeamMebers] = useState([]);
  const { register, handleSubmit, setValue, watch, formState, control, reset } =
    useForm({
      defaultValues: {
        leaderId: allocationById?.result?.leaderId?._id,
        allocations: [{
          leaderId: '',
          teamType: [{ teamName: '', teamMembers: [] }]
        }]
      },
    });
  useEffect(() => {
    if (allocationById?.result) {
      const formattedTeams = allocationById?.result
        ? {
            leaderId: allocationById?.result?.leaderId?._id,
            teamType: allocationById?.result?.teamType?.map((item) => ({
              teamName: item.teamName?._id||'',
              teamMembers: item?.teamMembers?.map(item=>item?._id)||[],
            })),
          }
        : null;
      reset({
        allocations: [formattedTeams],
      });
      allocationById.result.teamType?.forEach((team, index) => {
        getTeamMebers(team?.teamName?._id, index);
      });
    }
  }, [allocationById?.result, reset]);
  const {
    fields,
    append,
    prepend,
    remove: removeTeam,
    swap,
    move,
    insert,
    replace,
  } = useFieldArray({
    control,
    name: "allocations",
  });

  const handleClick = () => {
    submitRef.current = true;
  };
  const onSubmit = (data) => {
    if (submitRef.current === true) {
      // setIsComponentVisible(false);
      const response = updateAllocationMutation.mutate({id:id,data:[...data.allocations]});
    }
  };
  const { errors } = formState;

  useEffect(() => {
    if (departmentList) {
      setDepartmentOptions(
        departmentList?.map((item) => ({
          value: item?._id,
          label: item?.teamName,
        }))
      );
    }
  }, [departmentList]);

  useEffect(() => {
    if (employees) {
      setEmployeeOptions(
        employees?.map((item) => ({ value: item._id, label: item.name }))
      );
    }
  }, [employees]);

  const getTeamMebers = async (teamId, index) => {
    try {
      const response = await axios.post(baseUrl + "/team-members", {
        id: teamId,
      });
      setTeamMebers((prev) => ({
        ...prev,
        [index]: response.data?.result?.teamMembers?.map((item) => ({
          value: item._id,
          label: item.name,
        })),
      }));
    } catch (err) {
      throw new Error(err);
    }
  };

  return (
    <>
    <p className="absolute top-[40px] left-[200px] font-bold text-[20px]">{Location?.state?.edit?'EDIT ALLOCATION':'VIEW ALLOCATION'}</p>
        <form onSubmit={handleSubmit(onSubmit)}>
          <ul>
            {fields.map((item, index) => {
              return (
                <li key={item.id}>
                  <div className="form-row">
                    <div className="form-group">
                      <Controller
                        render={({ field }) => {
                          const selectedId = field?.value || "";
                          const selectedOption = employeeOptions?.find(
                            (emp) => emp.value === selectedId
                          );
    
                          return (
                            <ReactSelect
                            className={cn({"pointer-events-none":!Location?.state?.edit})}
    
                              styles={SelectCustomStyles}
                              isClearable
                              value={
                                selectedOption
                                  ? {
                                      value: selectedOption.value,
                                      label: selectedOption.label,
                                    }
                                  : null
                              }
                              onChange={(option) => {
                                const value = option ? option.value : null;
                                field.onChange(value); // Update field with the new value
                              }}
                              //   onChange={(selectedOptions) => {
                              //     const selectedValues = selectedOptions
                              //       ? selectedOptions.map((option) => option.value)
                              //       : [];
                              //     field.onChange(selectedValues);
                              //   }}
                              //   value={employeeOptions?.filter(
                              //     (option) =>
                              //       field.value && field.value.includes(option.value)
                              //   )}
                              options={employeeOptions}
                              placeholder={"Select EMployee ...."}
                            />
                          );
                        }}
                        name={`allocations.${index}.leaderId`}
                        control={control}
                      />
                    </div>
                  </div>
                  <NestedFields
                  showButtons={Location?.state?.edit}
                    errors={errors}
                    nestIndex={index}
                    removeTeam={removeTeam}
                    {...{ control, register }}
                    // employeeOptions={teamMembers?.[index]}
                    // modules={modules}
                    employeeOptions={teamMembers}
                    watch={watch}
                    setValue={setValue}
                    // project={project}
                    // projectStartDate={state?.state?.startDate}
                    // projectEndDate={state?.state?.endDate}
                  />
                </li>
              );
            })}
          </ul>
          <hr />
        { Location?.state?.edit&&
        <button
            type="button"
            onClick={() => append({})}
            className="flex items-center justify-center mt-[10px] gap-2 mb-[30px]"
          >
            <div
              className="w-[20px] h-[20px] bg-rgb(8 83 148,0.4) flex items-center justify-center rounded-[50%] text-[#eaf2ff]"
              style={{ backgroundColor: "rgb(8,83,148,0.3)" }}
            >
              +
            </div>
            Employee
          </button>
        }  
    
         { Location?.state?.edit&&<Button type="submit" ref={submitRef} onClick={handleClick}>
            Save
          </Button>}
        </form>
    </>
   
  );
};

export default EditAllocation;
