import axios from "../config/axiosAuth";
import { baseUrl } from "../config/baseUrl";

const getNotifications = async (to) => {
  try {
    const response = await axios.get(`${baseUrl}/getAllNotification`, {
      params: {
        to: to,
      },
    });
    return response.data.result;
  } catch (error) {
  }
};

const getAdminNotifications = async () => {
  try {
    const response = await axios.get(`${baseUrl}/getAllNotification`);
    return response.data.result;
  } catch (error) {
    console.log(error);
  }
};

const sendToAllNotification = async (data) => {
  const response = await axios.post(`${baseUrl}/sendNotificationToAll`, data);
  return response.data;
};

const sendToFewNotification = async (notificationData) => {
  const response = await axios.post(
    `${baseUrl}/sendNotificationToEmployees`,
    notificationData
  );
  return response.data;
};

export {
  getAdminNotifications,
  getNotifications,
  sendToAllNotification,
  sendToFewNotification,
};
