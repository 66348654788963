// import axios from "";
import axios from "../../config/axiosAuth";
import {
  Dialog,
  DialogContent,
  DialogTrigger,
} from "../../components/ui/dialog";
import { baseUrl } from "../../config/baseUrl";
import { useEffect, useState } from "react";
import { DescriptionModal } from "./DiscriptionModal";

export function CommentDialog({
  id,
  url,
  attendance,
  attendanceId,
  reportId,
  employeeId,
  report,
}) {
  const [data, setData] = useState([]);
  const [open, setOpen] = useState(false);


  const handleComment = async () => {

    // const postData = attendance
    //   ? { attendanceId: attendanceId, employeeId: id }
    //   : { id: id, reportId: reportId };
    let postData;
    if (attendance) {
      postData = { attendanceId: attendanceId, employeeId: employeeId };
    } else if (report) {
      postData = { reportId: reportId, employeeId:employeeId};
    } else {
      postData = { id: id, reportId: reportId };
    }
    try {
      const response = await axios.post(baseUrl + url, postData);

     
      setData([
        ...(response?.data?.adminComments || response?.data[0]?.adminComments),
        ...(response.data?.employeeComments ||
          response.data[0]?.employeeComments),
      ]);
    } catch (err) {
      throw new Error(err);
    }
  };

  useEffect(() => {
    if (open) {
      handleComment();
    }
  }, [open]);
  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>
        <button
          onClick={handleComment}
          className="button-17 text-xs w-28 !rounded-[10px] bg-white"
          variant="outline"
        >
          Comments
        </button>
      </DialogTrigger>
      <DialogContent className='!max-w-fit'>
        <>
          <div>
            <div
              style={{
                padding: "20px",
                textAlign: "center",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  borderBottom: "2px solid gray",
                  marginBottom: "10px",
                  alignItems: "center",
                }}
              ></div>
              <div className="h-[400px] overflow-auto">
                <div>
                  <table>
                    <thead>
                      <tr
                        style={{
                          backgroundColor: "#f1f4f7",
                          color: "gray",
                        }}
                      >
                        <th>Name</th>
                        <th>Comment</th>
                        <th>Comment By</th>
                        <th>Designation</th>
                      </tr>
                    </thead>
                    <tbody className="tableBody">
                      {data?.length > 0 ? (
                        data?.map((receiver, index) => (
                          <tr key={index}>
                            <td>{receiver?.commentBy || "-"}</td>
                            <td >{receiver?.comment?receiver?.comment?.length>14? <DescriptionModal trigger={<span className="flex w-[100px] overflow-hidden truncate">{`${receiver?.comment?.substring(0,14)}....`}</span>} data={receiver?.comment}/>:receiver?.comment:'-'}</td>

                            <td>
                              {receiver?.commentBy === "Admin"
                                ? "Admin"
                                : "Employee"}
                            </td>
                            <td>
                              {receiver?.designation ?receiver?.designation?.name:
                              receiver?.commentBy === "Admin"
                                ? "Admin"
                                : "Employee"}
                            </td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td colSpan="4">No Comments</td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </>
      </DialogContent>
    </Dialog>
  );
}
