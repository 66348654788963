import React, { useState } from "react";
import "./calenderCategory.css";
import { FaBorderAll } from "react-icons/fa";
import { LiaBirthdayCakeSolid } from "react-icons/lia";
import { FaRegCalendarAlt } from "react-icons/fa";
import { TbBrandMeetup } from "react-icons/tb";
import { MdOutlineEmojiEvents } from "react-icons/md";
import { CiSettings } from "react-icons/ci";
import MeetingModal from "../newCalender/meetingmodal";

const CalenderCategory = ({
  activeCategory,
  setActiveCategory,
  showModal,
  toggleModal,
  setReload
}) => {

  const categories = [
    {
      label: "All",
      value: "all",
      icon: (
        <FaBorderAll
          color={`${activeCategory === "all" ? "white" : "gray"}`}
          size={25}
        />
      ),
    },
    {
      label: "Project",
      value: "Projects",
      icon: (
        <CiSettings
          color={`${activeCategory === "Projects" ? "white" : "gray"}`}
          size={25}
        />
      ),
    },
    {
      label: "Birthday",
      value: "birthday",
      icon: (
        <LiaBirthdayCakeSolid
          color={`${activeCategory === "birthday" ? "white" : "gray"}`}
          size={25}
        />
      ),
    },
    {
      label: "Meetings",
      value: "meetings",
      icon: (
        <TbBrandMeetup
          color={`${activeCategory === "meetings" ? "white" : "gray"}`}
          size={25}
        />
      ),
    },
    {
      label: "Leaves",
      value: "leaves",
      icon: (
        <FaRegCalendarAlt
          color={`${activeCategory === "leaves" ? "white" : "gray"}`}
          size={25}
        />
      ),
    },
    {
      label: "Events",
      value: "events",
      icon: (
        <MdOutlineEmojiEvents
          color={`${activeCategory === "events" ? "white" : "gray"}`}
          size={25}
        />
      ),
    },
    {
      label: "Holidays",
      value: "holidays",
      icon: (
        <FaRegCalendarAlt
          color={`${activeCategory === "holidays" ? "white" : "gray"}`}
          size={25}
        />
      ),
    },
  ];

  const handleClick = (category) => {
    setActiveCategory(category.value);
  };
  return (
    <>
      {showModal &&( activeCategory==='meetings'||activeCategory==='holidays'||activeCategory==='events') &&(
        <MeetingModal showModal={showModal} toggleModal={toggleModal} activeCategory={activeCategory} setReload={setReload}/>
      )}
      {/* {showEventModal && (
        <EventModal closeModal={() => setShowEventModal(false)} />
      )} */}
      {/* <div className="w-full flex justify-center flex-col items-center rounded-lg">
        <p className="p-2 rounded-full border-2 border-[#3f51b5]">
          <img
            src="https://t4.ftcdn.net/jpg/03/64/21/11/360_F_364211147_1qgLVxv1Tcq0Ohz3FawUfrtONzz8nq3e.jpg"
            className="w-14 h-14 rounded-full"
            alt="Profile"
          />
        </p>
        <p className="text-[#3f51b5]">Vivek Singh</p>
      </div> */}
      <div className="sidebarcalender w-full mt-5 flex flex-wrap">
        {categories.map((category) => (
          <div
            key={category.value}
            className="w-[50%] p-1"
            onClick={(ev) => handleClick(category)}
          >
            <div
              className={`w-[100%] border-2 border-white h-[17vh] flex flex-col justify-center items-center sidebar-item rounded-lg ${
                activeCategory === category.value
                  ? "relative rounded-xl scale-110 active"
                  : ""
              }`}
            >
              <p>{category.icon}</p>
              <p>{category.label}</p>
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

export default CalenderCategory;
