import { Edit } from "lucide-react";
import { EditStatusModal } from "../../../PageResponsibility/src/admin/components/modals/EditStatus";
import moment from "moment";
import { DescriptionModal } from "../../../PageResponsibility/src/admin/components/modals/DiscriptionModal";
const options = [
    { label: "Pending", value: "Pending" },
    { label: "Completed", value: "Completed" },
    { label: "Draft", value: "Draft" },
    { label: "OnGoing", value: "OnGoing" },
  
    { label: "Approved", value: "Approved" },
  ];
export const columns = [
    {
      header: "S.No",
      cell: ({ row }) => {
        return <span>{row.index + 1}</span>;
      },
    },
  
    {
      accessorKey: "status",
      header: "Status",
      cell: ({ row }) => {
        const getStatusStyle = (status) => {
          switch (status) {
            case "Completed":
              return { color: "green", cursor: "pointer" };
            case "In Progress":
              return { color: "#e3b717", cursor: "pointer" };
            case "Planning":
              return { color: "#f56c27", cursor: "pointer" };
            case "Draft":
              return { color: "blue", cursor: "pointer" };
            default:
              return {};
          }
        };
  
        return (
          <EditStatusModal
            trigger={
              <div className="flex items-center justify-center gap-2">
                <span style={{ color: row.original.colorCode }}>
                  {row.original.status}
                </span>
                <span>
                  <Edit size={"12px"} />
                </span>
              </div>
            }
            url={`/employee-task`}
            id={row?.original?._id}
            invalidateQuery={"Mytasks"}
            options={options}
          />
        );
      },
    },
  
    
    {
        header: "Task",
        cell: ({ row }) => {
          return<DescriptionModal trigger={`${row.original?.task.substring(0,7)}...`} data={row.original?.task}/>
        },
      },
  
  
    {
      // accessorKey: "createdAt",
      cell: ({ row }) => moment(row.original.createdAt).format("YYYY-MM-DD"),
      header: "Task Added at",
    },
   
    
   
  
   
  ];