import { Delete, Trash } from "lucide-react";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import ReactSelect from "react-select";
import { SelectCustomStyles } from "../../../utils/styles";
import { IoIosRemoveCircle } from "react-icons/io";
import useEmployeeHook from "../../hooks/useEmployeeHook";
import { baseUrl } from "../../config/baseUrl";
import axios from "../../config/axiosAuth";
import { Input } from "../../components/ui/input";
import { Textarea } from "../../components/ui/textarea";
import { Button } from "../../components/ui/button";
import useAssignTaskHook from "../../hooks/AssignTaskHook";
import { useParams } from "react-router-dom";

export const EditAssignTask = ({}) => {
  const { register, control, formState, handleSubmit, setValue } = useForm();
  const { errors } = formState;
  const { departmentList } = useEmployeeHook({ departmentList: true });
const params=useParams()
  const [teamMembers, setTeamMebers] = useState([]);
  const [departmentOptions, setDepartmentOptions] = useState([]);
  const { TaskById, updateAssignTaskMutation } = useAssignTaskHook({id:params.id});

  useEffect(() => {
    setValue("task", TaskById?.task);
    setValue("department", TaskById?.department?._id);
    setValue("employeeId",TaskById?.employeeId?._id)
    getTeamMebers( TaskById?.department?._id,0)

  }, [TaskById]);

  const getTeamMebers = async (teamId, index) => {
    try {
      const response = await axios.post(baseUrl + "/team-members", {
        id: teamId,
      });
      setTeamMebers((prev) => ({
        ...prev,
        [index]: response.data?.result?.teamMembers?.map((item) => ({
          value: item._id,
          label: item.name,
        })),
      }));
    } catch (err) {
      throw new Error(err);
    }
  };
  useEffect(() => {
    if (departmentList) {
      setDepartmentOptions(
        departmentList?.map((item) => ({
          value: item._id,
          label: item.teamName,
        }))
      );
    }
  }, [departmentList]);

  const submit = (data) => {
    updateAssignTaskMutation.mutate({...data,id:params.id});
  };
  return (
    <form onSubmit={handleSubmit(submit)}>
      <div style={{ marginLeft: 20, marginTop: 20 }} className="form-row">
        {/* <label>Add Employee </label> */}
        <div className="form-group">
          <Controller
            rules={{
              required: "Please select a Team To add Task",
            }}
            name={`department`}
            control={control}
            render={({ field }) => {
              const selectedOption = departmentList?.find(
                (dept) => dept._id === field.value
              );

              return (
                <ReactSelect
                  {...field}
                  styles={SelectCustomStyles}
                  isClearable
                  value={
                    selectedOption
                      ? {
                          value: selectedOption._id,
                          label: selectedOption.teamName,
                        }
                      : null
                  }
                  onChange={(option) => {
                    return (
                      field.onChange(option ? option.value : null),
                      getTeamMebers(option?.value, 0)
                    );
                  }}
                  options={departmentOptions}
                  placeholder={"Select a team ...."}
                />
              );
            }}
          />
          {errors?.department?.message && (
            <p className="error-message"> {errors?.department?.message}</p>
          )}
        </div>

        <div className="form-group">
          <Controller
            rules={{
              required: "Please select atleast an Employee To add Allocation",
            }}
            name={`employeeId`} // Ensure this path matches your form data structure
            control={control}
            render={({ field }) => {
              return (
                <ReactSelect
                  {...field}
                  isMulti
                  styles={SelectCustomStyles}
                  options={teamMembers?.[0]}
                  onChange={(selectedOptions) => {
                    const selectedValues = selectedOptions
                      ? selectedOptions.map((option) => option.value)
                      : [];
                    field.onChange(selectedValues);
                  }}
                  value={(teamMembers?.[0] || [])?.filter(
                    (option) =>
                      field.value && field.value.includes(option.value)
                  )}
                  isClearable
                />
              );
            }}
          />
          {errors?.employeeId?.message && (
            <p className="error-message"> {errors?.employeeId?.message}</p>
          )}
        </div>

        {/* <button type="button" onClick={() => remove(k)}>
          <IoIosRemoveCircle color={"red"} />
        </button> */}
      </div>

      <div className="form-row px-4 mb-2">
        <Controller
          rules={{
            required: "Please Add task",
          }}
          name={`task`} // Ensure this path matches your form data structure
          control={control}
          render={({ field }) => {
            return (
              <Textarea
                {...field}
                className="bg-white min-h-[50px] min-w-[100%]"
              />
            );
          }}
        />
        {errors?.task?.message && (
          <p className="error-message"> {errors?.task?.message}</p>
        )}
      </div>
      <hr />
      <Button>Submit</Button>
    </form>
  );
};
const style = {
  control: (baseStyles, state) => ({
    ...baseStyles,

    height: "40px",
    border: " 1px solid #ccc",
    borderRadius: "5px",
  }),
};
