import { useMutation, useQuery, useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { AssignTaskService } from "../service/AssignTaskService";

const useAssignTaskHook = (params = {}) => {
  const fetchTasks = async (term) => {
    const data = await AssignTaskService.getAllTAsks(term);
    return data;
  };
  const fetchOneTAsk = async () => {
    const data = await AssignTaskService.getOneTAsk(params.id);
    return data;
  };

  const updateAssignTaskMutation = useMutation(
    (data) => {
      return AssignTaskService.editTask(data);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("fetchAllocations");
        navigate('/assign-task')
      },
    }
  );

  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { data, isLoading, isError } = useQuery(
    "fetchAllTAsks",
    () => fetchTasks(),
    {
      onSuccess: (data) => {},
    }
  );
  const { data: TaskById } = useQuery(
    ["fetchTAskById", params],
    () => fetchOneTAsk(params.id),
    { enabled: !!params.id },
    {
      onSuccess: (data) => {},
    }
  );

  const addAssignTaskMutation = useMutation(
    (data) => {
      return AssignTaskService.addTask(data);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("fetchAllTAsks");
        navigate('/assign-task')
      },
    }
  );

  const DeleteTaskMutation = useMutation(
    (id) => {
      return AssignTaskService.deleteTask(id);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("fetchAllTAsks");
        // toast.success("Project deleted successfully");
      },
    }
  );

  return {
    data,
    DeleteTaskMutation,
    updateAssignTaskMutation,
    addAssignTaskMutation,
    TaskById
  };
};

export default useAssignTaskHook;
