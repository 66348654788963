/* eslint-disable react-hooks/rules-of-hooks */
import React, { useState } from "react";
import { Textarea } from "../../components/ui/textarea";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTrigger,
} from "../../components/ui/dialog";
import { Controller, useForm } from "react-hook-form";
import { Button } from "../../components/ui/button";
import axios from "../../config/axiosAuth";
import { baseUrl } from "../../config/baseUrl";
import { toast } from "react-toastify";
import { useQueryClient } from "react-query";
import ReactSelect from "react-select";
import { baseUrlUser } from "../../../../../user/config/baseUrl";
import { SiInstatus } from "react-icons/si";

export const EditStatusModal = ({ id, url,trigger,invalidateQuery,options,user, workmode,developerStatus,testerStatus,isAdmin=true}) => {
  const[open,setOpen]=useState(false)
  const { register, handleSubmit, formState, reset ,control} = useForm();
  const { errors } = formState;
const queryClient=useQueryClient()

  const onSubmit = (data) => {
    let body;
    if(workmode){
      body={
        id: id,
        workmode: data.status,}
      }else if(developerStatus){
        body={
          id: id,
          developerStatus: data.status,
          isAdmin}
      
      }else if(testerStatus){
        body={
          id: id,
          testerStatus: data.status,
          isAdmin}
      
      }else{
        body={
          id: id,
          status: data.status,
        isAdmin}
      }
  
    axios
      .put((user?baseUrlUser:baseUrl) + url, body
        )
      .then((res) => {
        if (Array.isArray(invalidateQuery)) {
          invalidateQuery.forEach(query => {
            queryClient.invalidateQueries(query);
          });
        } else if (invalidateQuery) {
          queryClient.invalidateQueries(invalidateQuery);
        }
        setOpen(false)
      })

      .catch((err) => {
      });
  };

  const handleleadaddcomment = () => {};
const workMode=[
 { label:'WFH',value:'WFH'},
 { label:'WFO',value:'WFO'},

 { label:'Hybrid',value:'Hybrid'},


]
  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger>
        <div>{trigger}</div>
      </DialogTrigger>

      <DialogContent className='!min-h-[300px]'>
        <form onSubmit={handleSubmit(onSubmit)} className="grid gap-3">
          <DialogHeader>Edit Status</DialogHeader>
          <div>
          <Controller
                    render={({ field }) => (
                      <ReactSelect
                      onChange={(option) =>{
                        return(
                        field.onChange(option ? option.value : null))}
                      }
                        // styles={style}
                        isClearable
                        value={
                         ( options?options: workMode).find(
                            (option) => option.value === field.value
                          ) || null
                        }
                        options={options?options:workMode}
                        placeholder={"Select a status ...."}
                      />
                    )}
                    name={'status'}
                    control={control}
                  />
          </div>
          {/* <ReactSelect
          options={workMode}
            className="resize-none"
            placeholder="Edit Status"
            {...register("status", {
            })}
          /> */}

          {errors.comment && (
            <span className="error-message">{errors.comment.message}</span>
          )}

          <DialogFooter>
            {formState.isValid && (
              // <DialogClose asChild>
                <Button onClick={handleleadaddcomment} type="submit">
                  Save
                </Button>
              // </DialogClose>
            )}

            {!formState.isValid && <Button type="submit">Save</Button>}

            <DialogClose asChild>
              <Button>Close</Button>
            </DialogClose>
          </DialogFooter>
        </form>
      </DialogContent>
    </Dialog>
  );
};
