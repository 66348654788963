import React from 'react'
import { Button } from '../../components/ui/button'
import { useNavigate } from 'react-router-dom'
import { DataTable } from '../../components/table/Data-Table'
import { Columns } from './Columns'
import useAssignTaskHook from '../../hooks/AssignTaskHook'

const AssignTask = () => {
const navigate= useNavigate()
    const onClick=()=>{
        navigate('/add-assign-task')
    }
    const {data}=useAssignTaskHook()
  return (
    <div className='w-full h-full'>
        <Button onClick={onClick}>Add Task</Button>
        <DataTable columns={Columns} data={data||[]} defaultTheme={true}/>
      
    </div>
  )
}

export default AssignTask
