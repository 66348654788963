import { DeleteIcon, Edit, Trash } from "lucide-react";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import useAllocationHook from "../../hooks/useAllocationHook";
import CommonAlert from "../../components/ui/CommonAlert";
const NaviagteToEdit = ({ id }) => {
  const navigate = useNavigate();
  const goToEdit = () => {
    navigate(`/editAllocation/${id}`,{state:{edit:true}});
  };
  return (
    <div className="w-full flex items-center justify-center">
      <Edit color='blue'onClick={goToEdit} />
    </div>
  );
};
const ViewMembers = ({ id ,shouldEdit}) => {
  const navigate = useNavigate();
  const goToEdit = () => {
    navigate(`/editAllocation/${id}`,{state:{edit:false}});
  };
  return (
    <button className="button-17 !rounded-[10px] "onClick={goToEdit}>View</button>
  );
};


const Delete = ({ id }) => {
  const { DeleteAllocationMutation } = useAllocationHook();
  const deleteAllocation = async () => {
    await DeleteAllocationMutation.mutate(id);
  };
  return (
    <CommonAlert
    trigger={<Trash style={{ color: "red", margin: "auto" }} />}
    onClick={() => deleteAllocation()}
    heading={"Delete"}
    text={"Are you sure you want to delete ?"}
  />
);
    
  
};

export const Columns = [
  { header: "S.No", cell: ({ row }) => row.index + 1 },
  {
    header: "Leader",
    cell: ({ row }) => row.original?.leaderId?.name,
  },
  {
    header: "Allocated on",
    cell: ({ row }) => moment.utc(row.original.createdAt).format("DD-MM-YYYY"),
  },
  {
    header: "View Members",
    cell: ({ row }) => <ViewMembers  id={row?.original?._id} shouldEdit={false}/>
  },

  {
    header: "Edit",
    cell: ({ row }) => <NaviagteToEdit id={row?.original?._id}  />,
  },
  {
    header: "Delete",
    cell: ({ row }) => <Delete id={row?.original?._id} />,
  },
  // {header:'FullDay',
  //   cell:({row})=>row.allDay===true?'yes':'No'
  // },
];
