// import axios from "";
import axios from "../../config/axiosAuth";
import {
  Dialog,
  DialogContent,
  DialogTrigger,
} from "../../components/ui/dialog";
import { baseUrl } from "../../config/baseUrl";
import { useEffect, useState } from "react";
import { Eye } from "lucide-react";
import { DataTable } from "../../components/table/Data-Table";

export function ViewResponsibilities({ id, url, data,columnData, straightData,heading }) {
  const [finalData, setFinalData] = useState([]);
  //   const [data, setData] = useState([]);
  useEffect(() => {
    if(data){

      const transformedData = Object.entries(data).map(([key, value]) => {
        return { category: key, items: value.join(", ") };
      });
      setFinalData(transformedData);
    }
    }, []);

  const columns = [
    { header: "S.No", cell: ({ row }) => row.index + 1 },
    { header: "Responsibilty", cell: ({ row }) => row.original.category },
    { header: "Access List ", cell: ({ row }) => row.original.items },
  ];


  return (
    <Dialog>
      <DialogTrigger asChild>
        <div className="w-full flex items-center justify-center cursor-pointer">
          <Eye size={20} color={"lightgrey"} />
        </div>
      </DialogTrigger>
      <DialogContent className='min-w-[60%] !max-w-fit'>
        {/* <div>
          <div
            style={{
              padding: "20px",
              textAlign: "center",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                borderBottom: "2px solid gray",
                marginBottom: "10px",
                alignItems: "center",
              }}
            ></div>
            <table>
              <thead>
                <tr
                  style={{
                    backgroundColor: "#f1f4f7",
                    color: "gray",
                  }}
                >
                  <th>Name</th>
                  <th>Comment</th>
                  <th>Comment By</th>
                  <th>Designation</th>
                </tr>
              </thead>
              <tbody className="tableBody">
                {data?.length > 0 ? (
                  data?.map((receiver, index) => (
                    <tr key={index}>
                      <td>{receiver?.commentBy || "-"}</td>
                      <td>{receiver?.comment}</td>
                      <td>
                        {receiver?.commentBy === "Admin" ? "Admin" : "Employee"}
                      </td>
                      <td>{receiver?.designation || "-"}</td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="4">No Comments</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div> */}
        <DataTable
          columns={columnData?columnData:columns}
          data={(straightData?straightData:finalData)||[]}
          defaultTheme={true}
          heading={heading?heading:"Responsibilty list"}
          TableHeight={"250px"}
        />
      </DialogContent>
    </Dialog>
  );
}
