import React from 'react'
import useMyTaskHook from '../../hooks/UseMYtaskHook'
import { DataTable } from '../../../PageResponsibility/src/admin/components/table/Data-Table'
import { columns } from './Columns'

const MyTask = () => {
    const{myTasks}=useMyTaskHook()
  return (
    <div>
      <DataTable data={myTasks||[]} columns={columns} defaultTheme={true} />
    </div>
  )
}

export default MyTask
