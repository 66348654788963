// import React, { useEffect, useState } from "react";

// import "./sendNotification.css";
// import { baseUrlUser } from "../../config/baseUrl";
// import axios from '../../../PageResponsibility/src/admin/config/axiosAuth'
// import { QueryClient, useQueryClient } from "react-query";
// import moment from "moment";
// import { Pagination, PaginationContent, PaginationEllipsis, PaginationLink, PaginationNext, PaginationPrevious } from "../../components/ui/pagination";
// import { PaginationItem } from "../../../PageResponsibility/src/admin/components/ui/pagination";

// const UserNotification = () => {
//   const [notification, setNotification] = useState([]);
//   const queryclient=useQueryClient()
//   const getAllNotifications = async () => {
//     try {
//       const response = await axios.get(baseUrlUser + "/getAllNotification");
//       setNotification(response.data.result);
//       console.log(response.data.result);

     
//     } catch (error) {
//       console.log(error);
//     }
//   };

//   useEffect(() => {
//     getAllNotifications();
//   }, []);
// const markAsRead=async()=>{
//   try{

//     await axios.put(baseUrlUser + "/markRead", {});
//     queryclient.invalidateQueries('user')
//     getAllNotifications()

//   }catch{

//   }
// }
// const markSingleRead=async(id)=>{
//   try{

//     await axios.put(baseUrlUser + "/markSingleRead", {notificationId:id});
//     getAllNotifications()
//     queryclient.invalidateQueries('user')

//   }catch{

//   }
// }
//   return (
//     <>
//       <h2>All Notifications</h2>
      
//       <div className="notification_box max-h-[80%]">
//         <div onClick={markAsRead}>Mark All read</div>
//         <div className="w-full max-h-[80%] overflow-hidden flex flex-col gap-3">

//         {notification?.map((notification) => (
//           <div
//             className="notification cursor-pointer min-h-[75px] "
//             style={
//               notification?.isRead === false
//                 ? { backgroundColor: "#b9d2fb" }
//                 : {}
//             }
//             onClick={()=>markSingleRead(notification?._id)}
//           >
//             <div
//               style={{
//                 padding: "10px",
//               }}
//             >
//               <div className="notification__header flex justify-between ">
//                 <h6>{notification?.title}</h6>
//                 <p className="text-[8px] ">{moment.utc(notification?.createdAt).format('DD-MM-YYYY, HH:mm')}</p>
//               </div>
//               <div className="notification__body">
//                 <p>{notification?.body}</p>
//               </div>
//             </div>
//           </div>
//         ))}
//         </div>
        
//         <div className="flex items-center justify-end space-x-2 py-1 ">
//           <Pagination>
//             <PaginationContent>
//               <PaginationItem>
//                 <PaginationPrevious
//                 className={cn({'text-[#085394]':table.getCanPreviousPage()})}

//                   disabled={!table.getCanPreviousPage()}
//                   onClick={() => table.previousPage()}
//                 />
//               </PaginationItem>

//               {startIndex > 1 && (
//                 <PaginationItem>
//                   <PaginationEllipsis />
//                 </PaginationItem>
//               )}

//               {numbersArray.slice(startIndex - 1, endIndex).map((number) => (
//                 <PaginationItem key={number}>
//                   <PaginationLink
//                     isActive={
//                       table.getState().pagination.pageIndex + 1 === number
//                     }
//                     onClick={() => table.setPageIndex(number - 1)}
//                   >
//                     {number}
//                   </PaginationLink>
//                 </PaginationItem>
//               ))}

//               {endIndex < table.getPageCount() && (
//                 <PaginationItem>
//                   <PaginationEllipsis />
//                 </PaginationItem>
//               )}

//               <PaginationItem>
//                 <PaginationNext
//                 className={cn({'text-[#085394]':table.getCanNextPage()})}
//                   disabled={}
//                   onClick={}
//                 />
//               </PaginationItem>
//             </PaginationContent>
//           </Pagination>
//           {/* <select onChange={(e)=>setPerPage(e.target.value)} value={perPage} className="max-w-[50px] border-[lightgrey] mr-[20px] color-[lightgrey]">
//             <>

//           {perPageValues.map((column) => <option value={column}>{column}</option>)}
//             </>
//           </select> */}
//         </div>
//       </div>
//     </>
//   );
// };

// export default UserNotification;
import React, { useEffect, useState } from "react";
import "./sendNotification.css";
import { baseUrlUser } from "../../config/baseUrl";
import axios from '../../../PageResponsibility/src/admin/config/axiosAuth';
import { useQueryClient } from "react-query";
import moment from "moment";
import { 
  Pagination, 
  PaginationContent, 
  PaginationEllipsis, 
  PaginationLink, 
  PaginationNext, 
  PaginationPrevious, 
  PaginationItem 
} from "../../components/ui/pagination";

const UserNotification = () => {
  const [notification, setNotification] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10; // You can make this dynamic if needed
  const queryclient = useQueryClient();

  const getAllNotifications = async () => {
    try {
      const response = await axios.get(`${baseUrlUser}/getAllNotification`);
      setNotification(response.data.result);
      console.log(response.data.result);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getAllNotifications();
  }, []);

  const markAsRead = async () => {
    try {
      await axios.put(`${baseUrlUser}/markRead`, {});
      queryclient.invalidateQueries('user');
      getAllNotifications();
    } catch (error) {
      console.log(error);
    }
  };

  const markSingleRead = async (id) => {
    try {
      await axios.put(`${baseUrlUser}/markSingleRead`, { notificationId: id });
      getAllNotifications();
      queryclient.invalidateQueries('user');
    } catch (error) {
      console.log(error);
    }
  };

  // Pagination Logic
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentNotifications = notification.slice(indexOfFirstItem, indexOfLastItem);
  const totalPages = Math.ceil(notification.length / itemsPerPage);

  const handlePreviousPage = () => {
    if (currentPage > 1) setCurrentPage(prev => prev - 1);
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) setCurrentPage(prev => prev + 1);
  };

  const handlePageClick = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  // Generate page numbers
  const pageNumbers = [];
  for (let i = 1; i <= totalPages; i++) {
    pageNumbers.push(i);
  }

  return (
    <>
      <h2>All Notifications</h2>
      
      <div className="notification_box max-h-[80%]">
        <div onClick={markAsRead} className="mark-all-read cursor-pointer text-blue-500">
          Mark All as Read
        </div>
        <div className="w-full max-h-[80%] overflow-auto flex flex-col gap-3">
          {currentNotifications.length > 0 ? (
            currentNotifications.map((notification) => (
              <div
                key={notification._id}
                className="notification cursor-pointer min-h-[80px]"
                style={
                  notification.isRead === false
                    ? { backgroundColor: "#b9d2fb" }
                    : {}
                }
                onClick={() => markSingleRead(notification._id)}
              >
                <div style={{ padding: "10px" }}>
                  <div className="notification__header flex justify-between">
                    <h6>{notification.title}</h6>
                    <p className="text-[8px]">
                      {moment.utc(notification.createdAt).format('DD-MM-YYYY, HH:mm')}
                    </p>
                  </div>
                  <div className="notification__body">
                    <p>{notification.body}</p>
                  </div>
                </div>
              </div>
            ))
          ) : (
            <p>No notifications available.</p>
          )}
        </div>
        
        {/* Pagination Controls */}
        <div className="flex items-center justify-end space-x-2 py-1">
          <Pagination>
            <PaginationContent>
              <PaginationItem>
                <PaginationPrevious
                  disabled={currentPage === 1}
                  onClick={handlePreviousPage}
                  className={`${
                    currentPage > 1 ? 'text-[#085394] cursor-pointer' : 'text-gray-400 cursor-not-allowed'
                  }`}
                />
              </PaginationItem>

              {pageNumbers.map((number) => (
                <PaginationItem key={number}>
                  <PaginationLink
                    isActive={currentPage === number}
                    onClick={() => handlePageClick(number)}
                  >
                    {number}
                  </PaginationLink>
                </PaginationItem>
              ))}

              <PaginationItem>
                <PaginationNext
                  disabled={currentPage === totalPages}
                  onClick={handleNextPage}
                  className={`${
                    currentPage < totalPages ? 'text-[#085394] cursor-pointer' : 'text-gray-400 cursor-not-allowed'
                  }`}
                />
              </PaginationItem>
            </PaginationContent>
          </Pagination>
        </div>
      </div>
    </>
  );
};

export default UserNotification;
