/* eslint-disable react-hooks/rules-of-hooks */
import React, { useState } from "react";
import { Textarea } from "../../components/ui/textarea";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTrigger,
} from "../../components/ui/dialog";
import { useForm } from "react-hook-form";
import { Button } from "../../components/ui/button";
import useAddComment from "./reportCommentMutation";
import axios from "../../config/axiosAuth";
import { baseUrl } from "../../config/baseUrl";
import { toast } from "react-toastify";
import { baseUrlUser } from "../../../../../user/config/baseUrl";
import { useQueryClient } from "react-query";

export const AddCommentModal = ({ id, url,reportId,attendanceId ,employeeId,user,invalidateQueries='',refetch,reporter}) => {
  const { submitComment } = useAddComment();
  const { register, handleSubmit, formState, reset } = useForm();
const [open,setOpen]=useState(false)
  const { errors } = formState;
const queryClient=useQueryClient()
  const onSubmit = (data) => {
    let body;
    body={
      reportId:reportId,
      attendanceId:attendanceId,
      comment: data.comment,
      employeeId:employeeId,
      id: id,
      isAdmin:true
    }

    // if(reportId!==''){
    //   body={
    //     reportId:reportId,
    //     comment: data.comment,      }
    //     }
    //     else if(attendanceId!==''){
    //       body={

    //         attendanceId:attendanceId,
    //         comment: data.comment,
    //       }

    //     }
        // else if(employeeId!==''){
        //   body={

        //     employeeId:employeeId,
        //     comment: data.comment,
        //   }
        // }else{
        //   body={
        //     id: id,
        //     comment: data.comment,
        //   }
        // }
    axios
      .put(user?baseUrlUser:baseUrl + url, body)
      .then((res) => {
        setOpen(false)
        // toast.success(res.data.message)
        reset()
        if(reporter){

          refetch()
        }
        queryClient.invalidateQueries(invalidateQueries)
      })

      .catch((err) => {
        reset()
      });
  };

  const handleleadaddcomment = () => {};

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger>
        <button className="button-17 !rounded-[10px] !text-[10px]">Add Comment</button>
      </DialogTrigger>

      <DialogContent >
        <form onSubmit={handleSubmit(onSubmit)} className="grid gap-3">
          <DialogHeader>Add Comment</DialogHeader>
          <Textarea
            className="resize-none"
            placeholder="Add a comment"
            {...register("comment", {
              required: "This field is required",

              minLength: {
                value: 5,
                message: "Minimum length is 5",
              },

              maxLength: {
                value: 100,
                message: "Maximum length is 100",
              },

              validate: (value) => {
                return value.trim() !== "" || "This field is required";
              },
            })}
          />

          {errors.comment && (
            <span className="error-message">{errors.comment.message}</span>
          )}

          <DialogFooter>
            {formState.isValid && (
                <Button onClick={handleleadaddcomment} type="submit">
                  Save
                </Button>
              // <DialogClose asChild>
              // </DialogClose>
            )}

            {!formState.isValid && <Button type="submit">Save</Button>}

            <DialogClose asChild>
              <Button>Close</Button>
            </DialogClose>
          </DialogFooter>
        </form>
      </DialogContent>
    </Dialog>
  );
};
