import { useEffect, useState } from "react";
import useTimesheetHook from "../../hooks/useTimesheerHook";
import { CircularProgress, Pagination, Typography, Stack } from "@mui/material";
import useEventStore from "../../store/eventStore";
import { baseUrl } from "../../config/baseUrl";
import { DataTable } from "../../../PageResponsibility/src/admin/components/table/Data-Table";
import moment from "moment";

const EventTable = () => {
  const { otherEvents } = useEventStore();

  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize] = useState(3);

  // const [eventList,setEventList]=useState();

  const totalPages = Math.ceil(otherEvents?.length / pageSize);

  const renderTable = () => {
    const startIndex = (currentPage - 1) * pageSize;
    const endIndex = startIndex + pageSize;
    const data = otherEvents.slice(startIndex, endIndex);

    return data.map((item, index) => {
      const serialNumber = startIndex + index + 1;

      // const handleGetEventListing=()=>{
      //   axios
      //   .get(baseUrl +"/getOtherEvent")

      //   .then((res)=>{
      //     setEventList(res.data)
      //   })

      //   .catch((err)=>{
      //   })
      // }

      // useEffect(()=>{
      //   handleGetEventListing()
      // },[])

      return (
        <tr key={item._id}>
          <td>{serialNumber}</td>

          <td>{item?.description}</td>
          <td>{item?.subject}</td>
          <td>{moment.utc(item?.date).format('DD-MM-YYYY HH:mm')}</td>
        </tr>
      );
    });
  };
  const columns=[{header:'S.No',cell:({row})=>row.index+1},
    {header:'Title',cell:({row})=>row.original?.title},
    {header:'Description',cell:({row})=>row.original?.description},
    {header:'Date',cell:({row})=>moment(row.original?.date).format('DD-MM-YYYY')},
  ]

  return (
    <>
      {/* <div>
        <div
          className="table-container"
        p
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              borderBottom: "2px solid gray",
              marginBottom: "10px",
              alignItems: "center",
            }}
          >
            <h5
              style={{
                fontWeight: "bold",
                marginLeft:"10px"
              }}
            >
              Event
            </h5>
          </div>
          <table>
            <thead
              style={{
                color: "#c0c3c7",
                fontWeight: "bold",
              }}
            >
              <tr
                style={{
                  backgroundColor: "#f1f4f7",
                }}
              >
                <th>S.No.</th>
                <th>Description</th>
                <th>Subject</th>
                <th>Date</th>
              </tr>
            </thead>
            <tbody className="tableBody">
              {otherEvents !== "" && otherEvents?.length > 0 ? (
                renderTable()
              ) : (
                <tr>
                  <td colSpan="12">No project present</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      
      </div> */}
      <DataTable columns={columns} data={otherEvents||[]} defaultTheme={true} TableHeight={'200px'} heading={"Events"}/>
    </>
  );
};

export default EventTable;
