import { useMutation, useQuery, useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { projectServices } from "../service/projectService";
import useProjectStore from "../store/projectStore";
import { allocationService } from "../service/allocationService";

const useAllocationHook = (params = {}) => {
  const fetchAllocations = async (term) => {
    const data = await allocationService.getAllAllocations(term);
    return data;
  };
  const fetchOneAllocation = async (term) => {
    const data = await allocationService.getOneAllocation(term);
    return data;
  };

  const updateAllocationMutation = useMutation(
    (data) => {
      return allocationService.editAllocation(data);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("fetchAllocations");
        navigate('/allocations')
      },
    }
  );

  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { data, isLoading, isError } = useQuery(
    "fetchAllocations",
    () => fetchAllocations(),
    {
      onSuccess: (data) => {},
    }
  );
  const { data: allocationById } = useQuery(
    ["allocationById", params],
    () => fetchOneAllocation(params.id),
    { enabled: !!params.id },
    {
      onSuccess: (data) => {},
    }
  );

  const addAllocationMutation = useMutation(
    (data) => {
      return allocationService.addAllocation(data);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("fetchAllocations");
        navigate('/allocations')
      },
    }
  );

  const DeleteAllocationMutation = useMutation(
    (id) => {
      return allocationService.deleteAllocation(id);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("fetchAllocations");
        // toast.success("Project deleted successfully");
      },
    }
  );

  return {
    data,
    DeleteAllocationMutation,
    updateAllocationMutation,
    addAllocationMutation,
    allocationById
  };
};

export default useAllocationHook;
