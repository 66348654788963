import { toast } from "react-toastify";
import axios from "./../config/axiosAuth";
const { authHeaders } = require("../config/apiConfig");
const { baseUrl, baseUrlCommon } = require("../config/baseUrl");

const reportServices = {
  getAllReportAdmin: async () => {
    const res = await axios.get(`${baseUrl}/getAllReport`);
    return res.data.result;
  },
  getAllLeaders:async () => {
    const res = await axios.get(`${baseUrl}/leaders`);
    return res.data.result;
  },
  giveAccessToReport: async (data) => {
    const res = await axios.post(`${baseUrl}/giveAccess`, data);
    return res.data;
  },
  getLeaderById:async (id) => {
    const res = await axios.get(`${baseUrl}/leaders/${id}`);
    return res.data.result;
  },

  getAccessReports: async () => {
    const res = await axios.post(`${baseUrl}/getAccessedList`, {});
    return res.data.result;
  },

  removeAccess: async (data) => {
    const res = await axios.put(`${baseUrl}/takeAccessBack`, data);
    toast.success("Access removed successfully");
    return res.data;
  },

  submitCommentCommon: async (data) => {
    const res = await axios.put(`${baseUrlCommon}/addComment`, data);
    return res.data;
  },

  editCommentCommon: async (data) => {
    const res = await axios.put(`${baseUrlCommon}/editComment`, data);
    return res.data;
  },

  deleteCommentCommon: async (data) => {
    const res = await axios.post(`${baseUrlCommon}/deleteComment`, data);
    return res.data;
  },
};

export default reportServices;
