import { useMutation, useQuery, useQueryClient } from "react-query";
import { toast } from "react-toastify";
import useUserProjectStore from "../store/userProject";
import useUserStore from "../store/userStore";
import { userService } from "../services/userService";
import myTaskService from "../services/MyTaskservice";

const useMyTaskHook = (params = {}) => {
  const queryClient = useQueryClient();

  const fetchMyTasks = async () => {
    const res = await myTaskService.getMyTasks();
    return res;
  };

  const {
    data: myTasks,
    error: userTotalProjectsError,
    isLoading: userTotalProjectsLoading,
    isError: userTotalProjectsIsError,
  } = useQuery(["Mytasks", params.date], () => fetchMyTasks(), {
    onSuccess: (data) => {},
  });

  return {
    myTasks,
  };
};

export default useMyTaskHook;
