import React from 'react'
import useAllocationHook from '../../hooks/useAllocationHook'
import { DataTable } from '../../components/table/Data-Table'
import { Columns } from './Columns'

const AllocationList = () => {

    const{data}=useAllocationHook()
    

  return (
   <DataTable heading={"Allocations"} data={data||[]} defaultTheme={true} columns={Columns}/>
  )
}

export default AllocationList
