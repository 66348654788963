import { Button, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";

import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogTrigger,
} from "../../components/ui/dialog";
import { Edit } from "lucide-react";
import axios from "axios";
import ReactSelect from "react-select";
import moment from "moment";
import { baseUrl } from "../../config/baseUrl";
import { SelectCustomStyles } from "../../../utils/styles";
import { Input } from "../ui/input";

export const EditHolidayModal = ({
  isOpen,
  closeModal,
  id,
  data,
  activeCategory,
  recall,
}) => {
  const [open, setOpen] = useState(false);
  const {
    register,
    handleSubmit,
    reset,
    control,
    watch,
    setValue,
    formState,
    formState: { errors },
  } = useForm();

  // const { findHoliday } = useHolidayStore();
  const [employees, setEmployees] = useState([]);
  const [selectedOption, setSelectedOption] = useState([]);
  // const holidayToEdit = findHoliday(id);
  const handleAllEmployees = () => {
    axios
      .get(baseUrl + "/getAllEmployee")
      .then((res) => {
        const mapped = res.data.result.employees.map((result) => ({
          label: result?.name,
          value: result._id,
        }));
        setEmployees(mapped);
      })
      .catch((err) => {});
  };
  useEffect(() => {
    handleAllEmployees();
  }, []);
  useEffect(() => {
    if (data) {
      setValue("occasion", data.title);
      setValue("description", data.description);
      setValue("category", activeCategory);
      setValue("title", data.title);
      setValue("allDay", data?.allDay);
      setValue("endDate", moment.utc(data.end).format("YYYY-MM-DDTHH:mm"));
      if(!data?.allDay){

        setValue("date", moment.utc(data.date).format("YYYY-MM-DDTHH:mm"));
      }else{

        setValue("date", moment.utc(data.date).format("YYYY-MM-DD"));
      }

    }
  }, [data]);

  useEffect(() => {
    if (data && employees.length > 0) {
      const selectedEmployees = employees?.filter((option) =>
        data.employees?.some((item) => item._id === option.value)
      );
      setValue("employees", selectedEmployees);
    }
  }, [data, employees, setValue]);
  const handleEdit = (eventdata) => {
    let url;
    if (activeCategory === "Meeting") {
      url = "/meetings";
    } else if (activeCategory === "Holiday") {
      url = "/holidays";
    } else if (activeCategory === "Event") {
      url = "/events";
    }

    axios
      .put(baseUrl + url, {
        id: data._id,
        category: `${activeCategory}s`,
        title: eventdata.title,
        description: eventdata.description,
        date: eventdata.date,
        employees: eventdata.employees?.map((item)=>item.value),
        occasion: eventdata.title,
        allDay: eventdata.allDay,
        endDate: eventdata.endDate,
      })
      .then((res) => {
        setOpen(false);
        recall()
        closeModal();
      })
      .catch((err) => {});
  };

  // const handleDelete = (e) => {
  //   e.preventDefault();
  //   deleteHolidayMutation.mutate(id);
  //   closeModal();
  // };
  const handleChange = (selectedOptions) => {
    const filtered = selectedOptions.map((item) => item.value);
    setSelectedOption(filtered);
  };
  return (
    <Dialog open={open} onOpenChange={setOpen} fullWidth maxWidth="sm">
      <DialogTrigger>
        <Edit />
      </DialogTrigger>
      <DialogContent>
        <DialogTitle>{`Edit ${activeCategory}`}</DialogTitle>
        <form onSubmit={handleSubmit(handleEdit)}>
          <div>
            <TextField
              label="Title"
              type="text"
              fullWidth
              {...register("title", {
                minLength: {
                  value: 3,
                  message: "Title Name must be at least 3 characters",
                },

                maxLength: {
                  value: 20,
                  message: "Title must not exceed 20 characters",
                },

               

                validate: (value) =>
                  value?.trim() !== "" || "Title Name is required",
              })}
              helperText={errors.title?.message}
              error={errors.title ? true : false}
              style={{ marginTop: "8px", marginBottom: "8px"  }}
            />
          </div>
          {activeCategory === "Meeting" && (
             <Controller
             name="employees"
             control={control}
             render={({ field }) => (
               <ReactSelect
                 {...field}
                 isMulti
                 styles={SelectCustomStyles}
                 options={employees}  // Pass the full list of employees
                 onChange={(selectedOptions) => {
                   const selectedValues = selectedOptions
                     ? selectedOptions.map((option) => option.value)
                     : [];
                   field.onChange(selectedOptions);
                 }}
                 value={employees.filter((option) =>
                   field.value?.some((val) => val.value === option.value)
                 )}
                 isClearable
               />
             )}
           />
           
            // <Controller
            //   name={"employees"} 
            //   control={control}
            //   render={({ field }) => {
            //     return (
            //       <ReactSelect
            //         {...field}
            //         isMulti
            //         styles={SelectCustomStyles}
            //         options={employees}
            //         onChange={(selectedOptions) => {
            //           const selectedValues = selectedOptions
            //             ? selectedOptions.map((option) => option.value)
            //             : [];
            //           field.onChange(selectedValues);
            //         }}
            //         value={employees?.filter(
            //           (option) =>
            //             field.value && field.value.includes(option.value)
            //         )}
            //         isClearable
            //       />
            //     );
            //   }}
            // />
          )}
          <div>
            <TextField
              label="Description"
              type="text"
              fullWidth
              {...register("description", {
                minLength: {
                  value: 3,
                  message: "Description must be at least 3 characters",
                },

                

                // pattern: {
                //   value: /^[a-zA-Z\s]*$/,
                //   message: "Description must contain letters only",
                // },

                validate: (value) =>
                  value?.trim() !== "" || "Description is required",
              })}
              helperText={errors.description?.message}
              error={errors.description ? true : false}
              // value={description}
              // onChange={(e) => setDescription(e.target.value)}
              style={{ marginTop: "8px" }}
            />
          </div>
          <div className="flex items-center h-[20px] text-[14px] gap-2 mt-1 mb-1">

          <span>All Day</span>
            <Input
              label="AllDay"
              type="checkbox"
              name="allDay"
              className={'w-[15px] h-[15px] !mt-0 '}
              // fullWidth
              defaultChecked={data?.allDay}
              {...register("allDay", {})}
              helperText={errors.allDay?.message}
              error={errors.allDay ? true : false}
              InputLabelProps={{
                shrink: true,
              }}
              style={{ marginTop: "8px" }}
            />
          
          </div>
          {watch("allDay") === true && (
            <>
              <div>
                <TextField
                  label="Date"
                  type="date"
                  name="date"
                  fullWidth
                  {...register("date", {})}
                  helperText={errors.date?.message}
                  error={errors.date ? true : false}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  InputProps={{
                    inputProps: {
                    //   min: new Date().toISOString().split("T")[0],
                    },
                    placeholder: "dd/mm/yyyy",
                  }}
                  style={{ marginTop: "8px" }}
                />
              </div>
            </>
          )}
          {watch("allDay") !== true && (
            <>
              <div>
                <TextField
                  label="Start Date"
                  type="datetime-local"
                  name="date"
                  fullWidth
                  {...register("date", {})}
                  helperText={errors.date?.message}
                  error={errors.date ? true : false}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  InputProps={{
                    inputProps: {
                    },
                  }}
                  style={{ marginTop: "8px" }}
                />
              </div>
              <div>
                <TextField
                  label="End Date"
                  type="datetime-local"
                  name="endDate"
                  fullWidth
                  {...register("endDate", {})}
                  helperText={errors.endDate?.message}
                  error={errors.endDate ? true : false}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  InputProps={{
                    inputProps: {
                      min: watch('date'),
                    },
                    // placeholder: "dd/mm/yyyy",
                  }}
                  style={{ marginTop: "8px" }}
                />
              </div>
            </>
          )}
          <br />

          <Button
            variant="contained"
            color="primary"
            // onClick={handleEdit}
            type="submit"
          >
            {` Edit ${activeCategory}`}
          </Button>
          <Button
            variant="contained"
            color="primary"
            type="submit"
            onClick={()=>(setOpen(false))}
            style={{
              marginLeft: "10px",
              backgroundColor: "red",
              border: "none",
            }}
          >
            {`Cancel`}
          </Button>
        </form>
      </DialogContent>
    </Dialog>
  );
};
