import ArticleIcon from "@mui/icons-material/Article";
import InsightsIcon from "@mui/icons-material/Insights";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import StoreIcon from "@mui/icons-material/Store";
import { NavLink } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import useEmployeeHook from "../../hooks/useEmployeeHook";
import useProjectHook from "../../hooks/useProjectHook";
import "./widget.scss";

const Widget = ({ type }) => {
  let data;

  const { totalEmp } = useEmployeeHook();
  const { totalProjects, totalCompletedProjects, totalPendingProjects } =
    useProjectHook();

  switch (type) {
    case "user":
      data = {
        total: totalEmp,
        title: "Total Employee",
        isMoney: false,
        link: (
          <NavLink
            to={"/employee"}
            style={{
              textDecoration: "none",
              color: "rgb(45, 43, 43)",
            }}
          >
            See all Employee
          </NavLink>
        ),
        icon: (
          <PersonOutlinedIcon
            className="icon"
            style={{
              color: "crimson",
              backgroundColor: "rgb(255 255 255 / 67%)",
            }}
          />
        ),
      };
      break;
    case "order":
      data = {
        total: totalProjects,
        title: "Projects",
        isMoney: false,
        link: (
          <NavLink
            to={"/projects"}
            style={{
              textDecoration: "none",
              color: "rgb(45, 43, 43)",
            }}
          >
            View all projects
          </NavLink>
        ),
        icon: (
          <InsightsIcon
            className="icon"
            style={{
              backgroundColor: "rgb(255 255 255 / 67%)",
              color: "goldenrod",
            }}
          />
        ),
      };
      break;
    case "earning":
      data = {
        total: totalCompletedProjects,
        title: "Completed Project",
        isMoney: true,
        link: (
          <NavLink
            to={"/projects"}
            style={{
              textDecoration: "none",
              color: "rgb(45, 43, 43)",
            }}
          >
            View All Completed Projects
          </NavLink>
        ),
        icon: (
          <StoreIcon
            className="icon"
            style={{
              backgroundColor: "rgb(255 255 255 / 67%)",
              color: "green",
            }}
          />
        ),
      };
      break;
    case "balance":
      data = {
        total: totalPendingProjects,
        title: "Pending Project",
        isMoney: true,
        link: (
          <NavLink
            to={"/projects"}
            style={{
              textDecoration: "none",
              color: "rgb(45, 43, 43)",
            }}
          >
            View All Projects
          </NavLink>
        ),

        icon: (
          <ArticleIcon
            className="icon"
            style={{
              backgroundColor: "rgb(255 255 255 / 67%)",
              color: "purple",
            }}
          />
        ),
      };
      break;
    default:
      break;
  }

  return (
    <>
      <div className="widget ">
        {/* <div className="left">
        <div >

      
    
        </div>
      </div> */}
        {/* <div className="right"> */}
        {/* <div className="percentage positive">
          <KeyboardArrowUpIcon />
          {diff} %
        </div> */}
        {/* {data.icon} */}
        {/* </div> */}
        <div className="top">
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
              borderBottom: "1px solid #ccc",
              fontWeight: "bold",
            }}
          >
            <span className="title">{data.title}</span>
            <span className="counter">
              {data.isMoney && <>{data.isMoney} </>} {data.total}
            </span>
          </div>
          <div
            style={{
              color: "gray",
              fontSize: "13px",
            }}
          >
            Total
          </div>
        </div>

        <div className="bottom">
          <span
            style={{
              fontSize: "12px",
            }}
            className="link"
          >
            {data.link}
          </span>
        </div>
      </div>
    </>
  );
};

export default Widget;
