import React, { useEffect, useState } from "react";
import Chart from "react-google-charts";
import { useSearchParams } from "react-router-dom";
import TaskTable from "../../components/table/taskTable";
import useProjectHook from "../../hooks/useProjectHook";
import Loading from "../protectedPages/Loading";
import "./taskmanagement.scss";
import useGraphHook from "./useGraphHook";
import { useTaskManagementHook } from "./useTaskMangementHook";

function TaskManagement() {
  const [currentPage, setCurrentPage] = useState(1);
  const { data } = useProjectHook();
  const [searchParams, setSearchParams] = useSearchParams();
  const projectId = searchParams.get("projectId");

  const [pieChart, setPieChart] = useState(null);
  const [barGraph, setBarGraph] = useState(null);

  const projects = data || [];
  const selectedProject =
    data?.length > 0 ? data?.find((p) => p._id === projectId) : null;

  const {
    data: taskData,
    isLoading,
    isFetching,
    isError,
    refetch,
  } = useTaskManagementHook(projectId, currentPage, 8);

  const { graph, pie } = useGraphHook(projectId);


  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
    setSearchParams({ projectId, page: newPage, limit: 8 });
  };

  useEffect(() => {
    setSearchParams({ projectId, page: currentPage, limit: 8 });
  }, [projectId, currentPage, setSearchParams]);

  useEffect(() => {
    drawCharts();
  }, [selectedProject, pie, graph]);

  const drawCharts = () => {
    if (graph) {
      setBarGraph(drawBarGraph());
    }
    if (pie) {
      setPieChart(drawPieChart());
    }
  };

  const drawPieChart = () => {
    const data = [
      ["Task", "Percentage"],
      ...pie?.map(({ _id, percentage }) => [_id, percentage]),
    ];

    const options = {
      title: "All Project task percentage",
      is3D: true,
      slices: [
        { color: "#3366cc" },
        { color: "#dc3912" },
        { color: "#ff9900" },
      ],
      backgroundColor: "#eaf2ff",
      animation: {
        startup: true,
        duration: 2000,
        easing: "out",
      },
    };

    return (
      <Chart
        width={"100%"}
        height={"300px"}
        chartType="PieChart"
        loader={<div>Loading Chart</div>}
        data={data}
        options={options}
        rootProps={{ "data-testid": "1" }}
      />
    );
  };

  const drawBarGraph = () => {
    const data = [
      ["Date", "Percentage"],
      ...graph?.map(({ date, percentage }) => [date, percentage]),
    ];

    const options = {
      title: "Percentage Over Time",
      hAxis: { title: "Date" },
      vAxis: { title: "Percentage", minValue: -1, maxValue: 100 },
      legend: { position: "none" },
      backgroundColor: "#eaf2ff",
      chartArea: { width: "80%", height: "70%" },
    };

    return (
      <Chart
        width={"100%"}
        height={"300px"}
        chartType="AreaChart"
        loader={<div>Loading Chart</div>}
        data={data}
        options={options}
        rootProps={{ "data-testid": "2" }}
      />
    );
  };

  useEffect(() => {
    refetch();
  }, [selectedProject, currentPage, refetch]);

  return (
    <>
      <div className="taskmanagement__container">
        <div className="taskmanagement__selectProject">
          <label htmlFor="">Select Project</label>
          <select
            name=""
            id=""
            value={selectedProject ? selectedProject._id : ""}
            onChange={(e) => {
              const selectedProjectId = e.target.value;
              setSearchParams({ projectId: selectedProjectId });
            }}
          >
            <option value="" disabled>
              {"Select a project"}
            </option>

            {projects.map((project) => (
              <option key={project._id} value={project._id}>
                {project.projectName}
              </option>
            ))}
          </select>
        </div>
        <div className="taskmanagment__calenderContainer">
          <div className="taskmanagement__graph">{barGraph}</div>
          <div className="taskmanagement__piechart">{pieChart}</div>
        </div>
        <div className="taskmanagement_table">
          <TaskTable
            data={taskData}
            isLoading={isFetching}
            isError={isError}
            currentPage={currentPage}
            handlePageChange={handlePageChange}
            isFetching={isFetching}
          />
        </div>
        {(!projectId || isLoading) && <Loading />}
      </div>
    </>
  );
}

export default TaskManagement;
