import axios from "../config/axiosAuth";
import { baseUrl } from "../config/baseUrl";

const AssignTaskService = {
  getAllTAsks: async () => {
    
    try {
      const response = await axios.get(`${baseUrl}/employee-task`);
      return response.data;
    } catch (error) {
      throw new Error(error);
    }
  },
 
  
  
 

  

  getOneTAsk: async (id) => {
    const res = await axios.get(`${baseUrl}/employee-task/${id}`);
    return res.data;
  },

  

 

  editTask: async ( data) => {
    const res = await axios.put(`${baseUrl}/employee-task`, data);
    return res.data.result;
  },

  deleteTask: async (id) => {
    const res = await axios.delete(`${baseUrl}/employee-task/${id}`);
    return res.data.result;
  },

  addTask: async (data) => {
    const res = await axios.post(`${baseUrl}/employee-task`, data);
    return res.data.result;
  },
 
};

export { AssignTaskService };
