import { CommentDialog } from "../../components/modals/CommentDialog";
import { AddCommentModal } from "../reports/AddCommentModal";

export const columns = [
  {
    header: "S.No",
    cell: ({ row }) => {
      return <span>{row.index + 1}</span>;
    },
  },

  {
    accessorKey: "employeeName",
    header: "Name",
    cell: ({ row }) => {
      return row.original?.employeeName;
    },
  },
  {
    accessorKey: "role",
    header: "Role",
    cell: ({ row }) => {
      return row.original?.role;
    },
  },
  {
    cell: ({ row }) => {
      return row.original?.designation?.name;
    },
    header: "Designation",
  },
  {
    accessorKey: "employeeId",
    header: "Employee Id",
  },

  {
    accessorKey: "salary",
    header: "Salary(PM)",
  },
  {
    accessorKey: "salaryPerDay",
    header: "Salary(PD)",
  },
  {
    accessorKey: "finalSalary",
    header: "Final calculated salary",
  },
  {
    header: "Paid Leave",
    cell: ({ row }) => 1
  },
  {
    header: "UnPaid Leave",
    cell: ({ row }) => row.original?.leaveDays?row.original.leaveDays-1:'0'

  },
  {
    accessorKey: "leaveDays",
    header: "Leaves Taken",
  },
  {
    accessorKey: "lateDays",
    header: "Late Days",
  },
  {
    accessorKey: "halfDays",
    header: "Half Days ",
  },{
    accessorKey: "deductedSalaryForLeaves",
    header: "Salary deduction for Leaves",
  },
  {
    accessorKey: "deductedSalaryForHalfDays",
    header: "Salary deduction for HalfDays",
  },
  {
    accessorKey: "deductedSalaryForLateDays",
    header: "Salary deduction for LateDays",
  },

  // {
  //   header: "Add Comment",
  //   cell: ({ row }) => <AddCommentModal id={row.original._id} />,
  // },

  // {
  //   cell: ({ row }) => {
  //     return <CommentDialog payroll={row.original} />;
  //   },
  //   header: "Comments",
  // },
];
