import zIndex from "@mui/material/styles/zIndex";

export const tableStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "60%",
    backgroundColor: "#fff",
    maxHeight: "80vh",
    overflow: "auto",
  },
};
export const SelectCustomStyles = {
  control: (provided, state) => ({
    ...provided,
    minHeight: '100px', // Adjust this value as needed
  }),
  container:(provided,state)=>({...provided}),
  control: (baseStyles, state) => ({
    ...baseStyles,

    height: "40px",
    border: " 1px solid #ccc",
    borderRadius: "5px",
  }),
  menu:(provided,state)=>({
...provided,
zIndex:100
  }),
  valueContainer: (provided, state) => ({
    ...provided,
    height: '80%',

    overflowY: 'auto',
    alignItems: 'flex-start',
    marginTop:'5px'

  }),
  multiValue: (provided, state) => ({
    ...provided,
    maxWidth: '45%',
  }),
  indicatorsContainer: (provided, state) => ({
    ...provided,
    height: '100%',
  }),
};
export const getStatusStyle = (status) => {
  switch (status) {
    case "Completed":
      return { color: "green" ,cursor:'pointer'};
    case "InProgress":
      return { color: "#e3b717",cursor:'pointer' };
    case "Planning":
      return { color: "#f56c27",cursor:'pointer' };
      case"Draft":
      return{color:'blue',cursor:'pointer'}
      case"Pending":
      return{color:'red',cursor:'pointer'}
    default:
      return {color:'purple',cursor:'pointer'};
  }
};