import React, { useEffect, useState } from "react";
import {
  Button,
 
  FormHelperText,
  MenuItem,
  TextField,
} from "@mui/material";
// import MenuItem from "@mui/material/MenuItem";
// import Select from "@mui/material/Select";
import { Option  } from '@mui/base/Option'

// import { Option as BaseOption, optionClasses } from '@mui/base/Option';
import { useForm, Controller } from "react-hook-form";
import useTaskMangementEditHook from "../TaskManagementEditHook.hook";
import { Select, SelectContent, SelectItem } from "../../../../PageResponsibility/src/admin/components/ui/select";
import { Dialog, DialogContent, DialogFooter, DialogTitle, DialogTrigger } from "../../../../PageResponsibility/src/admin/components/ui/dialog";
import { Edit } from "lucide-react";
import { AlertDialogTrigger } from "../../../../PageResponsibility/src/admin/components/ui/alert-dialog";
import { cn } from "../../../../PageResponsibility/src/utils/lib";
// import useTaskMangementHook from "../TaskManagement.hooks";

export const EditTaskModal = ({  task }) => {
  const { editTaskMutation } = useTaskMangementEditHook();
const[open,setOpen]=useState()
console.log(task)
  const { control, handleSubmit, setValue, formState,reset } = useForm({
    defaultValues: {
      taskCompleted: task?.task || "",
      
      status: task?.status || "",
    },
  });
  const { errors } = formState;

  useEffect(() => {
    setValue("taskCompleted", task?.task);
    if(task?.status){
      const selected=statusOptions.filter((item)=>item===task?.status)
      setValue("status", task?.status);
    }
  }, [task, setValue]);

  const onSubmit = (data) => {
    console.log("Form data:", data);

    editTaskMutation.mutate({
      _id: task._id,
      data: { completed: data.taskCompleted, status: data.status },
    });
reset()
    setOpen(false)
  };
  const statusOptions = ['Pending', 'InProgress', 'Completed', 'Planning', 'Approved', 'Delivered'];
  return (
    <Dialog open={open} onOpenChange={setOpen}>
       <DialogTrigger asChild className="w-[75px]">
          <p className={cn("lg:text-white text-white text-left cursor-pointer flex p-2 w-full text-center",)} onClick={()=>setOpen(true)}> <Edit className="icon  m-auto text-[blue]" /> </p>
      </DialogTrigger>
      <DialogContent>
      <DialogTitle>Edit Task</DialogTitle>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Controller
            name="taskCompleted"
            // readOnly={true}
            control={control}
            rules={{
              required: "This Field cannot be empty ",
            }}
            render={({ field }) => (
              <TextField
              readOnly={true}
                {...field}
                fullWidth
                label="Task Completed"
                margin="normal"
                variant="filled"
                disabled
              />
            )}
          />
          {errors.taskCompleted && (
            <p className="error-message">{errors.taskCompleted.message}</p>
          )}
<Controller
  name="status"
  control={control}
  defaultValue=""
  rules={{
    required: "This field cannot be empty",
  }}
  render={({ field, fieldState: { error } }) => (
    <>
      {/* <Select
        {...field}
        fullWidth
        displayEmpty
        inputProps={{ "aria-label": "Select Status" }}
      >
        
        <MenuItem value="" disabled>
          Select Status
        </MenuItem>
        <MenuItem value="Pending">Pending</MenuItem>
        <MenuItem value="Delivered">Delivered</MenuItem>
      </Select> */}
      <Controller
            name="status"
            control={control}
            // defaultValue={.status}
            render={({ field }) => (
              <TextField
                {...field}
                select
                label="Status"
                variant="outlined"
                fullWidth
                SelectProps={{
                  native: true,
                }}
              >
                <option aria-label="None" value="" />
                {statusOptions.map((option) => (
                  <option key={option} value={option}>
                    {option}
                  </option>
                ))}
              </TextField>
            )}
          />
      {error && <p error>{error.message}</p>}
    </>
  )}
/>
          {errors.status && (
            <p className="error-message">{errors.status.message}</p>
          )}

          <DialogFooter>
            <Button type="button" onClick={()=>setOpen(false)} color="secondary">
              Cancel
            </Button>
            <Button type="submit" color="primary">
              Save
            </Button>
          </DialogFooter>
        </form>
      </DialogContent>
    </Dialog>
  );
};
