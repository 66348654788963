import React from "react";
import useUserStore from "../../store/userStore";
import "./UserProfile.css"; // Import the CSS file for styling
import { Edit } from "lucide-react";
import editPen from './editpen.svg'
import useUserHook from "../../hooks/userHook";
import { cn } from "../../../utils/lib";
import { TbCameraPlus } from "react-icons/tb";
const UserProfile = () => {
  const user = useUserStore((state) => state.user);
const{EditUserProfileMutation}=useUserHook()

  const handleImageUpload = async (event) => {
    // console.log(token);
    const selectedFile = event.target.files[0];
    console.log(selectedFile,'');
    const formData = new FormData();
      
            formData.append("image",selectedFile)
       
    const data = {
      image: selectedFile,
    };
    
    const Response = await EditUserProfileMutation.mutateAsync(formData);
    console.log(Response, "image upload");
  };
  return (
    <div className="user-profile-container">
      {/* <Edit /> */}
      <div className="profile-info">
        <div className="relative">

       { user?.image? <img
          className="profile-image rounded-[50%]"
          src={user?.image}
          alt="Profile"
        />:<img
          className={cn("profile-image ",{'rounded-[50%]':user?.gender ==='female'})}
          src={(user?.gender==='female')?'https://st2.depositphotos.com/1006318/5909/v/450/depositphotos_59095633-stock-illustration-businesswoman-profile-icon.jpg':"https://st3.depositphotos.com/15648834/17930/v/600/depositphotos_179308454-stock-illustration-unknown-person-silhouette-glasses-profile.jpg"}
          alt="Profile"
        />}
        <div className="flex items-center justify-center w-[26px] h-[26px] rounded-[50%] bg-primary border-[2px] border-[#8EDDEB] absolute bottom-[10%] left-[98%] translate-x-[-98%] translate-y-[-10%]">
                {/* <img
                  src={editPen}
                  alt="edit pen"
                 
                /> */}
                <TbCameraPlus  className="w-[12px] h-[12px] cursor-pointer text-white"
                  onClick={() => document.getElementById("fileInput").click()}/>
              </div>

              <input
                id="fileInput"
                type="file"
                accept="image/*"
                className="w-3 absolute h-3 bg-white border-none cursor-pointer bottom-[31px] right-10 z-0 opacity-0" // Hide the input but maintain its functionality
                onChange={handleImageUpload}
              />
        </div>
        <div className="user-details">
          <span className="username  pointer-events-none">{user.name}</span>
          <span className="email">{user.email}</span>
        </div>
      </div>

      <div className="user-details-section">
        <div className="user-detail">
          <label>Mobile Number</label>
          <input
            defaultValue={user.number}
            type="text"
            className="custom-input pointer-events-none"
            placeholder="Enter phone number"
            readOnly
            
          />
        </div>

        <div className="user-detail">
          <label>State</label>
          <input
            defaultValue={user.state}
            type="text"
            className="custom-input pointer-events-none"
            placeholder="Enter state"
            readOnly
           
          />
        </div>

        <div className="user-detail">
          <label>Date of Birth</label>
          <input
            value={user.dob?.split("T")[0]}
            type="date"
            className="custom-input pointer-events-none"
            placeholder="Enter date of birth"
            readOnly
          />
        </div>

        <div className="user-detail">
          <label>Date of Joining</label>
          <input
            value={user.dateOfJoining?.split("T")[0]}
            type="date"
            className="custom-input  pointer-events-none"
            placeholder="Enter date of joining"
            readOnly
          />
        </div>

        <div className="user-detail">
          <label>Designation</label>
          <input
            value={user.designation?.name}
            type="text"
            className="custom-input  pointer-events-none"
            placeholder="Enter designation"
            readOnly
          />
        </div>
        <div className="user-detail">
          <label>Role</label>
          <input
            value={user.role}
            type="text"
            className="custom-input  pointer-events-none"
            placeholder="Enter designation"
            readOnly
          />
        </div>

        <div className="user-detail">
          <label>Employee ID</label>
          <input
            value={user.employeeId}
            type="text"
            className="custom-input  pointer-events-none"
            placeholder="Enter employee ID"
            readOnly
          />
        </div>
      </div>
    </div>
  );
};

export default UserProfile;
