import { Edit } from "lucide-react";
import { EditStatusModal } from "../../../PageResponsibility/src/admin/components/modals/EditStatus";
import moment from "moment";
import { AddCommentModal } from "../../components/modals/AddCommentModal";
import { CommentDialog } from "../../components/modals/CommentDialog";
import { DailyLogs } from "../../../PageResponsibility/src/admin/components/modals/DailyLogsModal";
import { DescriptionModal } from "../../../PageResponsibility/src/admin/components/modals/DiscriptionModal";
const getRowStyle = (date) => {
    const day = moment(date).format("dddd");
    if (day === "Saturday") {
      return {
        backgroundColor: "Green",
        padding: "7px",
        color: "white",
        minWidth: "100px",
        maxWidth: "150px",
        margin: "auto",
      };
    } else if (day === "Sunday") {
      return {
        backgroundColor: "Green",
        padding: "7px",
        color: "white",
        minWidth: "100px",
        maxWidth: "150px",
        margin: "auto",
      };
    }
    return {};
  };
  const options=[{label:"Present",value:'PRESENT'},
    {label:"Absent",value:'ABSENT'},
    {label:"Leave",value:'LEAVE'},
  ]
export const MonthlyColumn = [
    {
      header: "S.No",
      cell: ({ row }) => {
        return <div style={getRowStyle(row.original?.date)}>{row.index + 1}</div>;
      },
    },
  
    {
      header: "Date",
      cell: ({ row }) => {
        return (
          <div style={getRowStyle(row.original?.date)}>{moment(row.original?.date).format('DD-MM-YYYY')}</div>
        );
      },
    },
    {
      header: "Day",
      cell: ({ row }) => {
        return (
          <div style={getRowStyle(row.original?.date)}>
            {moment(row.original?.date).format("dddd")}
          </div>
        );
      },
    },
   
    {
      header: "Work Mode",
      cell: ({ row }) => {
        return (
          <div className="flex items-center justify-center gap-2">
            <span>
              {row.original?.workmode === null
                ? "notupdatedYet"
                : row.original?.workmode}
            </span>
            {/* {row.original?.status !== "WEEKEND" && (
              <EditStatusModal
                trigger={<Edit size={"12px"}/>}
                url={'/updateAttendance'} id={row?.original?._id} user={true} workmode={'workmode'} invalidateQuery={'attendance'}
              />
            )} */}
          </div>
        );
      },
    },
    {
      header: "Daily Logs",
      cell: ({ row }) => {
        return <DailyLogs id={row.original._id} url={'/daily-attendance'} user={true} date={row?.original?.date} invalidateQuery={'attendance'}/>;
      },
    },
    {
      header: "Status",
      cell: ({ row }) => {
        return (
          <div className="flex items-center justify-center gap-2">
            <span>
              {row.original?.status === null
                ? "notupdatedYet"
                : row.original?.status}
            </span>
            {/* {row.original?.status !== "WEEKEND" && (
              <EditStatusModal
                trigger={<Edit size={"12px"}/>}
                url={'/updateAttendance'} id={row?.original?._id} user={true} options={options}  invalidateQuery={'attendance'}
              />
            )} */}
          </div>
        );
      },
    },
    {
      header: "Working Hours",
      cell: ({ row }) => {
        return (
          <div style={getRowStyle(row.original?.date)}>
            {row.original?.workHours}
          </div>
        );
      },
    },
  
    {
      header: "Login Location",
      cell: ({ row }) => {
        return (
          <div className="min-w-[150px] max-w-[150px] p-1 !break-words" style={getRowStyle(row.original?.date)}>
            
       {row.original?.loggedInLocation?row.original?.loggedInLocation?.length>14? 
       <DescriptionModal trigger={<span className="flex w-[100px] overflow-hidden truncate">{`${row.original?.loggedInLocation?.substring(0,14)}....`}</span>} data={row.original?.loggedInLocation}/>:row.original?.loggedInLocation:'-'}

          </div>
        );
      },
    },
    {
      header: "Logout Location",
      cell: ({ row }) => {
        return (
          <div className="min-w-[150px] max-w-[150px] p-1 !break-words" style={getRowStyle(row.original?.date)}>
            {/* {row.original?.loggedOutLocation} */}
       { row.original?.loggedOutLocation?  row.original?.loggedOutLocation?.length>14? 
       <DescriptionModal 
       trigger={<span className="flex w-[100px] overflow-hidden truncate">{`${row.original?.loggedOutLocation?.substring(0,14)}....`}</span>}
        data={row.original?.loggedOutLocation}/>
        :row.original?.loggedOutLocation:'-'}

          </div>
        );
      },
    },
  
    {
      header: "Add Comment",
      cell: ({ row }) =>
        row.original?.status !== "WEEKEND" && (
          <AddCommentModal
            id={row.original?._id}
            model='attendance'
           
          />
        ),
    },
    {
      cell: ({ row }) => {
        return (
          row.original?.status !== "WEEKEND" && (
            <CommentDialog
           
              id={row.original?._id}
             model='attendance'
            />
          )
        );
      },
      header: "Comments",
    },
    // {
    //   cell: ({ row }) => {
    //     return;
    //   },
    //   header: "Action",
    // },
  ];