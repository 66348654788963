import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// const axios = Axios.create({
//   // baseURL: 'https://jsonplaceholder.typicode.com',
//   // other configurations
// })
const handleUnauthorizedError = (error) => {
  // toast.error(error);
  toast.error('error');

  setTimeout(() => {
    localStorage.clear();

    window.location.href = "/";
  }, 1000);
};

const handleNetworkError = (error) => {
  // console.error("Network error:", error);
  // toast.error("Network error. Please check your internet connection.");
};

axios.interceptors.request.use((config) => {
  const token = localStorage.getItem("token");
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
    config.headers.sessionId = localStorage.getItem("sessionId");
  }
  return config;
});

axios.interceptors.response.use(
  (response) => {
    const successMessage = response?.data?.message;
    if (
      successMessage &&
      ["post", "put", "delete"].includes(response.config.method.toLowerCase())
    ) {
      toast.success(successMessage);
    }

    return response;
  },
  (error) => {
    if (error.response) {
      if (error.response.status === 401 || error.response.status === 403) {
        // toast.error(error?.response.message);
        handleUnauthorizedError(error?.response.message);
      } else if (error.response.status === 400) {
        const errorMessage =
          error.response?.data && error.response?.data?.message
            ? error.response?.data?.message
            : "Bad request. Please check your input.";

        toast.error(errorMessage);
      } else if (error?.response?.data && error.response?.data?.message) {
        const errorMessage = error.response?.data?.message;
        if (
          error.config &&
          error.config.method &&
          error.config.method.toLowerCase() !== "get"
        ) {
          toast.error(errorMessage);
        }
      } else {
        console.error("HTTP error:", error.response.status);
        console.error("Response data:", error.response?.data);
      }
    } else if (error.request) {
      handleNetworkError(error);
    } else {
      console.error("Request error:", error.message);
    }
  }
);

export default axios;
