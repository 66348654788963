import moment from "moment";
import { EditStatusModal } from "../../../PageResponsibility/src/admin/components/modals/EditStatus";
import { Edit, Eye } from "lucide-react";
import { AddCommentModal } from "../../components/modals/AddCommentModal";
import { CommentDialog } from "../../components/modals/CommentDialog";
import { DescriptionModal } from "../../../PageResponsibility/src/admin/components/modals/DiscriptionModal";
import axios from "../../config/axiosAuth";
import { useNavigate } from "react-router-dom";
// import fetch from 'node-fetch';
const options = [
  { label: "Pending", value: "Pending" },
  { label: "Completed", value: "Completed" },
  { label: "Draft", value: "Draft" },
  { label: "OnGoing", value: "OnGoing" },

  { label: "Approved", value: "Approved" },
];
console.log(fetch, "fetchhhhhhhhhhhh");
const HandleDownload = ({ row }) => {
const navigate=useNavigate()

  const handleDownload = (e, url, fileName) => {
    e.preventDefault();

    fetch(url)
      .then((response) => response.blob())
      .then((blob) => {
        const blobUrl = window.URL.createObjectURL(blob); // No need to wrap in new Blob
        const link = document.createElement("a");
        link.href = blobUrl;
        link.download = fileName || "downloaded-file"; // Use passed fileName or default
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(blobUrl);
      })
      .catch((error) => {
        console.error("Error fetching the file:", error);
      });
  };

  const documents = row.original?.myDocs?.map((item, index) => (
    <a
      key={index}
      href="#"
      onClick={(e) => handleDownload(e, item?.url, item?.file_name)} // Pass fileName
      className="text-blue-500 hover:underline"
    >
      {item?.file_name}
    </a>
  ));

  // Prevent recursive call by simply returning documents or "No-docs"
  return <>{documents?.length ? <div  style={{
    width: "100px",
    padding: "0",
    fontSize: "11px",
  }}
  className="button-17" onClick={()=>navigate('/myDocs',{state:{documents: row.original?.myDocs}})}>View Documents</div> : "No-docs"}</>;
};
// const handleDownload = (e,url,fileName) => {
//   e.preventDefault();

//   axios.get(url, { responseType: 'blob' })
//     .then((response) => {
//       const blob = new Blob([response.data]);
//       const downloadUrl = window.URL.createObjectURL(blob);
//       const link = document.createElement("a");
//       link.href = downloadUrl;
//       link.download = fileName || "downloaded-file";

//       document.body.appendChild(link);
//       link.click();

//       document.body.removeChild(link);
//       window.URL.revokeObjectURL(downloadUrl);
//     })
//     .catch((error) => {
//       console.error("Error fetching the file:", error);
//     });
// };
export const columns = [
  {
    header: "S.No",
    cell: ({ row }) => {
      return <span>{row.index + 1}</span>;
    },
  },

  {
    accessorKey: "status",
    header: "Status",
    cell: ({ row }) => {
      const getStatusStyle = (status) => {
        switch (status) {
          case "Completed":
            return { color: "green", cursor: "pointer" };
          case "In Progress":
            return { color: "#e3b717", cursor: "pointer" };
          case "Planning":
            return { color: "#f56c27", cursor: "pointer" };
          case "Draft":
            return { color: "blue", cursor: "pointer" };
          default:
            return {};
        }
      };

      return (
        <EditStatusModal
          trigger={
            <div className="flex items-center justify-center gap-2">
              <span style={{ color: row.original.colorCode }}>
                {row.original.status}
              </span>
              <span>
                <Edit size={"12px"} />
              </span>
            </div>
          }
          url={`/updateProjectStatus/${row?.original?._id}`}
          id={row?.original?._id}
          invalidateQuery={"projects"}
          options={options}
          isAdmin={false}
        />
      );
    },
  },

  {
    accessorKey: "projectName",
    header: "Project Name",
  },
  {
    accessorKey: "projectStartDate",
    type: "date",
    header: "Start Date",
    cell: ({ row }) => {
      const startDate = row.getValue("projectStartDate");
      return (
        <span className="text-[green]">{startDate?.substring(0, 10)}</span>
      );
    },
  },
  {
    accessorKey: "projectEndDate",
    type: "date",
    header: "End Date",
    cell: ({ row }) => {
      const endDate = row.getValue("projectEndDate");
      return <span className="text-[blue]">{endDate?.substring(0, 10)}</span>;
    },
  },
  {
    cell: ({ row }) =>
      row?.original?.projectDescription?.length > 14 ? (
        <DescriptionModal
          trigger={
            <span className="flex w-[100px] overflow-hidden truncate">{`${row?.original?.projectDescription.substring(
              0,
              14
            )}....`}</span>
          }
          data={row?.original?.projectDescription}
        />
      ) : (
        row?.original?.projectDescription
      ),

    header: "Description",
  },
  {
    // accessorKey: "createdAt",
    cell: ({ row }) => moment(row.original.createdAt).format("YYYY-MM-DD"),
    header: "Project Added at",
  },
  {
    cell: ({ row }) =>row.original?.myModule?.length>0?<ShowModules row={row}/>:'',
    header: "Modules",
  },
  {
    header: "Documents ",
    cell: ({ row }) => <HandleDownload row={row}/>
  },
  {
    header: "Add Comment",
    cell: ({ row }) => (
      <AddCommentModal id={row.original._id} model={"project"} />
    ),
  },
  {
    header: "Comment",

    cell: ({ row }) => {
      return <CommentDialog commonId={row.original._id} model="project" />;
    },
  },

  // {
  //   header: "Add Employees",
  //   cell: ({ row }) => {
  //     return (
  //       <Link to={`/addEmployee/?projectId=${row.original._id}`}>
  //         <button className="button-17">Add Employees</button>
  //       </Link>
  //     );
  //   },
  // },

  // {
  //   accessorKey: "edit",
  //   header: "Edit",
  //   cell: ({ row }) => {
  //     return (
  //       <span className="flex justify-center">
  //         <Link to={`/editProject/${row.original._id}`}>
  //           <RiEditBoxFill className="text-lg" />
  //         </Link>
  //       </span>
  //     );
  //   },
  // },

  // {
  //   accessorKey: "details",
  //   header: "Details",
  //   cell: ({ row }) => {
  //     return (
  //       <Link to={`/ProjectDetails/${row.original._id}`}>
  //         <button className="button-17 !rounded-[10px]">Details</button>
  //       </Link>
  //     );
  //   },
  // },
];
const ShowModules=({row})=>{

  return<DescriptionModal trigger={<Eye className="text-[15px] text-lightgrey"/>} data={row.original?.myModule?.join(", ")}/>

}