import React from "react";
import { Link } from "react-router-dom"; // If you're using React Router
import ArrowBackIcon from "@mui/icons-material/ArrowBack"; // Import the Material-UI icon

const Error = () => {
  const handleClearStorage = () => {
    localStorage.clear();
  };
  return (
    <div className="error-page">
      <div className="error-content">
      <h1 className="error-heading">We're currently restarting our server to enhance performancw this process should be completed shortyly.</h1>
        <p className="error-para">
          Please wait a few minutes and try refreshing the page.
        </p>
        <Link
          style={{
            textDecoration: "none",
            width: "fit-content",
            cursor: "pointer",
            marginRight: "20px",
          }}
          to={"/userdashboard"}
        >
          <ArrowBackIcon />
          Back to Dashboard Page
        </Link>
        <Link to="/" onClick={handleClearStorage} className="btn-back-to-home">
          Back to Login
        </Link>
      </div>
    </div>
  );
};

export default Error;
