import React, { useEffect } from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import StepContent from "@mui/material/StepContent";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { useMutation, useQuery } from "react-query";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import useUserStore from "../../store/userStore";
import { baseUrlUser } from "../../config/baseUrl";
import axios from '../../../PageResponsibility/src/admin/config/axiosAuth'

const steps = [
  {
    label: "Check In",
    description: "Please check in to the office.",
  },
  {
    label: "Proceed To Check Out",
    description: "You have successfully checked in. You can now proceed.",
  },
  {
    label: "Check Out",
    description: "If you are leaving the office, please check out.",
  },
];

const currentDate = new Date().toISOString().substring(0, 10);
// const currentDate = "2023-11-10";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function OfficeCheckInOut() {
  const { user } = useUserStore();

  const [activeStep, setActiveStep] = React.useState(0);
  const [error, setError] = React.useState(null);
  const [openConfirmation, setOpenConfirmation] = React.useState(false);

  const handleOpenConfirmation = () => {
    setOpenConfirmation(true);
  };

  const handleCloseConfirmation = () => {
    setOpenConfirmation(false);
  };

  const handleConfirmCheckout = () => {
    getLocation()
      .then((location) => {
        checkOutMutation.mutate(location);
      })
      .catch((err) => setError("Error: " + err.message));
    setOpenConfirmation(false);
  };

  const fetchTodayAttendance = async () => {
    const { data } = await axios.get(baseUrlUser + "/todayAttendance");
    return data.result;
  };

  const {
    data: attendanceData,
    isLoading,
    isError,
  } = useQuery("todayAttendance", fetchTodayAttendance);

  useEffect(() => {
    if (
      attendanceData &&
      attendanceData?.checkIn &&
      currentDate === attendanceData?.checkIn?.substring(0, 10)
    ) {
      setActiveStep(1);
    }
    if (
      attendanceData &&
      attendanceData?.checkOut &&
      currentDate === attendanceData?.checkOut?.substring(0, 10)
    ) {
      setActiveStep(2);
    }
  }, [attendanceData]);

  const checkInMutation = useMutation(
    (location) =>
      axios.post(`${baseUrlUser}/checkInAttendance`, {
        employeeId: user._id,
        latitude: location.latitude,
        longitude: location.longitude,
      }),
    {
      onSuccess: () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
      },
      onError: (error) => {
        setError("Already CheckedIn For Toady. Please Refresh the page");
      },
    }
  );

  const checkOutMutation = useMutation(
    (location) =>
      axios.post(`${baseUrlUser}/checkOutAttendance`, {
        employeeId: user._id,
        latitude: location.latitude,
        longitude: location.longitude,
      }),
    {
      onSuccess: () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setTimeout(() => {
          localStorage.removeItem("token");
        }, 500);
      },
      onError: (error) => {
        setError("Error: " + error.message);
      },
    }
  );

  const getLocation = () => {
    return new Promise((resolve, reject) => {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          resolve({ latitude, longitude });
        },
        (error) => reject(error),
        { enableHighAccuracy: true }
      );
    });
  };

  const handleNext = () => {
    setError(null);

    getLocation()
      .then((location) => {
        if (activeStep === 0) {
          checkInMutation.mutate(location);
        } else if (activeStep === 2) {
          handleOpenConfirmation();
        } else {
          setActiveStep((prevActiveStep) => prevActiveStep + 1);
        }
      })
      .catch((err) => setError("Error: " + err.message));
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
    setError(null);
  };

  // Loading and error states
  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (isError) {
    return <div>Error fetching data</div>;
  }

  return (
    <Box sx={{ width: 500 }}>
      <Stepper activeStep={activeStep} orientation="vertical">
        {steps.map((step, index) => (
          <Step key={step.label}>
            <StepLabel>
              {index === 0 && attendanceData?.checkIn
                ? `Check In Time ${new Date(
                    attendanceData.checkIn
                  ).toLocaleTimeString()}`
                : index === 2 && attendanceData?.checkOut
                ? `Check Out Time ${new Date(
                    attendanceData.checkOut
                  ).toLocaleTimeString()}`
                : step.label}
            </StepLabel>
            <StepContent>
              <Typography>
                {index === 0 && attendanceData?.checkIn
                  ? `Checked in at ${new Date(
                      attendanceData.checkIn
                    ).toLocaleTimeString()}`
                  : index === 2 && attendanceData?.checkOut
                  ? `Checked out at ${new Date(
                      attendanceData.checkOut
                    ).toLocaleTimeString()}`
                  : step.description}
              </Typography>
              {error && (
                <Typography color="error" sx={{ mt: 1 }}>
                  {error}
                </Typography>
              )}
              <Box sx={{ mb: 2 }}>
                <div>
                  {index === steps.length - 1 && !attendanceData?.checkOut ? (
                    <Typography>
                      Checked out at{" "}
                      {new Date(attendanceData?.checkOut).toLocaleTimeString()}{" "}
                      - Thank you!
                    </Typography>
                  ) : (
                    <React.Fragment>
                      {index === 1 && !attendanceData?.checkOut && (
                        <Button
                          variant="outlined"
                          onClick={handleOpenConfirmation}
                          sx={{ mt: 1, mr: 1 }}
                          disabled={attendanceData?.checkOut !== undefined}
                        >
                          Confirm Check Out
                        </Button>
                      )}
                      {index !== 1 && index !== 2 && (
                        <Button
                          variant="contained"
                          onClick={handleNext}
                          sx={{ mt: 1, mr: 1 }}
                          disabled={
                            checkInMutation.isLoading ||
                            checkOutMutation.isLoading
                          }
                        >
                          {index === steps.length - 2 ? "Proceed" : "Next"}
                        </Button>
                      )}
                    </React.Fragment>
                  )}
                  <Button
                    disabled={
                      index === 0 || (index === 2 && !attendanceData?.checkIn)
                    }
                    onClick={handleBack}
                    sx={{ mt: 1, mr: 1 }}
                  >
                    Back
                  </Button>
                </div>
              </Box>
            </StepContent>
          </Step>
        ))}
      </Stepper>
      <Dialog
        open={openConfirmation}
        onClose={handleCloseConfirmation}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        TransitionComponent={Transition}
      >
        <DialogTitle id="alert-dialog-title">{"Confirm Check Out"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to leave the office? If you confirm, you will
            be checked out for today.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseConfirmation}>Cancel</Button>
          <Button onClick={handleConfirmCheckout} autoFocus>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
